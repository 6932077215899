import React from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import './Login.scss';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../stylesMUI';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '.././asset/image/logo.png';
import logonew from '.././asset/image/logonew.png';
import axiosClient from "../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import LoginButton from "../asset/components/LoginButton";
import Directions from '../asset/enums/enum-direction';
import { useStore } from '../hooks/use-store';

function Login() {
  const loginUserStore = useStore().loginUserStore;
  const { t } = useTranslation();
  const client = axiosClient();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [textError, setTextError] = React.useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setTextError("");
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get('email');
    let password = data.get('password');

    if(email === "" || password === "")
    {
      setTextError("All fields must be filled in");
    }
    else
    {
      const params = new FormData();
      params.append('username', email as string);
      params.append('password', password as string);

      //console.log(params);
      client .post("/login", {username:email, password:password}) 
        .then((response) => {       
          loginUserStore.setLoginUser(response.data.data.token, email as string);
          navigate(Directions.Machines, { replace: true });
        }) 
        .catch((error) => { 
          console.error("Error:", error); 
          const resp = JSON.parse(error.request.response);
          setTextError(resp.message);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className='box-login'>
          <img src={logo} className="App-logo"/>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              variant='filled'
            />
            <FormControl variant='filled' fullWidth margin="normal" required>
              <InputLabel htmlFor="filled-adornment-password">{t("password")}</InputLabel>
              <FilledInput type={showPassword ? 'text' : 'password'}
                endAdornment = {
                  <InputAdornment position="end">
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff />: <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                id ="password"
                name = "password"
                autoComplete='password'
              />
            </FormControl>

            <Typography id='LoginErrorMessage' className="error-color">{textError}</Typography>
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color = "secondary"
              className = "button-submit"
            >
              <b>{t("login")}</b>
              </Button>
            {/*<LoginButton textButton={t("login")}/>*/}
          </Box>
        </Box>
      </Container>
      <Box className='bottom-main'>
        <Box className='bottom-main-logo'>
        <img src={logonew} className="App-logoNew" alt="logoNew"/>
        </Box>
      </Box>
    </ThemeProvider>   
  )
}

export default Login;