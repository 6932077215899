import { observable, action, makeAutoObservable, computed } from 'mobx';
import { LoginUserStore } from './login-user-store';
import axiosClient from "../services/axiosInstance";
import {formatTimestamp, millisecondsToTime} from '../data-converter'
import {MachinesItem, SensorsData, CalibrationSensorData, CalibrationData, DevicesItem, SensorsItem, NotificationItem} from './machine-store-interfaces';
import { TypeSensor } from '../asset/enums/enum-type-sensor';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


export class MachineStore{
    machines: MachinesItem[];
    selectedMachines:string;
    selectedIdCalibrationData:string;
    selectedUUIDMachine: string;
    sensorDataSelectedMachine:SensorsData[];
    minimumCalibrationData:number;
    calibrationSensorDataSelectedMachine: CalibrationSensorData[];
    calibrationDataSelectedMachine: CalibrationData[];   
    private LoginUserStore:LoginUserStore; 
    minSecondsCalibration: number;
    minMinutesCalibration: number;
    showLoagingBar:boolean;
    selectedNotificationInterval:number|null;
    selectedReasonStatusTemplateId:number|null;
    dictionaryReasonTemplates: Map<number, string>;


    constructor(loginUserStore:LoginUserStore){
        this.LoginUserStore = loginUserStore;
        this.machines = [];
        this.sensorDataSelectedMachine = [];
        this.selectedMachines = '';
        this.selectedIdCalibrationData = '';
        this.selectedUUIDMachine = '';
        this.minimumCalibrationData = 600;
        this.calibrationSensorDataSelectedMachine = [];
        this.calibrationDataSelectedMachine = [];
        this.minSecondsCalibration = 10;
        this.minMinutesCalibration = 0;
        this.showLoagingBar = false;
        this.selectedNotificationInterval=null;
        this.selectedReasonStatusTemplateId = null;
        this.dictionaryReasonTemplates = new Map();
        makeAutoObservable(this, {machines: observable, selectedMachines: observable, sensorDataSelectedMachine: observable, selectedIdCalibrationData: observable, calibrationSensorDataSelectedMachine: observable,
            calibrationDataSelectedMachine: observable, minSecondsCalibration: observable, minMinutesCalibration: observable, selectedUUIDMachine: observable, showLoagingBar: observable, selectedNotificationInterval: observable,
            selectedReasonStatusTemplateId: observable, dictionaryReasonTemplates: observable
        });
    }
    
    setMachines = action(async() => {
        this.showLoagingBar = true;
        let listIdMachines: string[] = [];
        await this.getReasonTemplates();
        try {
            const response = await this.LoginUserStore.client.get("/machines");
            response.data.data.forEach((value: any) => {
                listIdMachines.push(value.id);
            });
        } catch (error) {
            console.error("Error fetching machines:", error);
        }

        var currentMachines: MachinesItem[]=[];
        var haveIndex = false;
        try {         
            const fetchDetailsPromises = listIdMachines.map(async (item: string) => {
                try {
                    const response = await this.LoginUserStore.client.get("/machines/" + item);
                    const itemData = response.data.data;
                    var devices: DevicesItem|null = null;
                    if(itemData.device!=null){
                        devices = {
                            machineId: itemData.device.machine_id,
                            uuid: itemData.device.uuid,
                            name: itemData.device.name,
                            description: itemData.device.description,
                            comment: itemData.device.comment,
                            lastSeen: itemData.device.last_seen
                        };
                    }
                    const sensors: SensorsItem[] = itemData.sensors.map((sensor: any) => {
                        let sensorItem:SensorsItem = {
                            id: sensor.id,
                            machineName: itemData.name,
                            sensorName: TypeSensor[sensor.type],
                            companyId: sensor.company_id,
                            machineId: sensor.machine_id,
                            type: sensor.type,
                            axis: sensor.axises,
                            interval: sensor.interval,
                            isLiveValue: sensor.is_live_value,
                            deletedAt: sensor.deleted_at,
                            createdAt: sensor.created_at,
                            updatedAt: sensor.updated_at,
                            isActive: sensor.is_active,
                            notifications: [],
                            notificationInterval: itemData.notification_interval,
                            reasonStatusTemplateId: itemData.reason_status_template_id
                        };

                        sensor.notifications.map((notificationRaw: any)=>{
                            let notification: NotificationItem = {
                                id: notificationRaw.id,
                                companyId: notificationRaw.company_id,
                                userId: notificationRaw.user_id,
                                calibrationId: notificationRaw.calibration_id,
                                userEmail: notificationRaw.user_email,
                                isEmail: notificationRaw.is_email,
                                isPush: notificationRaw.is_push,
                                deletedAt: notificationRaw.deleted_at,
                                createdAt: notificationRaw.created_at,
                                updatedAt: notificationRaw.updated_at,
                                assignedIserId: notificationRaw.assigned_user_id,
                                sensorId: notificationRaw.sensor_id,
                                emailPrefix: notificationRaw.email_prefix,
                                isSendOnce: notificationRaw.is_send_once,
                            };

                            sensorItem.notifications.push(notification);
                        });

                        return sensorItem;
                    });
                    const machine = {
                        id: itemData.id,
                        name: itemData.name,
                        cooldownTime: itemData.cooldown_time,
                        warmupTime: itemData.warmup_time,
                        companyId: itemData.company_id,
                        userId: itemData.user_id,
                        deletedAt: itemData.deleted_at,
                        createdAt: itemData.created_at,
                        updatedAt: itemData.updated_at,
                        deviceTokem: itemData.device_tokens,
                        notificationInterval: itemData.notification_interval,
                        reasonStatusTemplateId: itemData.reason_status_template_id,
                        description: itemData.description,
                        sensors: sensors,
                        device: devices
                    };

                    if(this.selectedMachines === machine.id){
                        haveIndex = true;
                    }

                    currentMachines.push(machine);
                } catch (error) {
                    console.error("Error fetching machine details for ID:", item, error);
                }
            });

            await Promise.all(fetchDetailsPromises);
        } catch (error) {
            console.error("Error processing machine details:", error);
        }

        currentMachines = currentMachines.sort((a, b) => a.name.localeCompare(b.name));

        if(currentMachines.length>0 && (this.selectedMachines === '' || haveIndex== false)){
            var selMachine = currentMachines[0].id;           
            if(currentMachines[0].sensors != null){
                for(let i = 0; i < currentMachines[0].sensors.length; i++){
                    let item = currentMachines[0].sensors[i];
                    if(item.isActive && item.id!=null){
                        selMachine = item.id;
                        break;
                    }
                }
            }
            this.setSelectedItem(selMachine);
        }

        if(currentMachines.length==0){
            this.setSelectedItem('');
        }

        this.showLoagingBar = false;

        this.machines = currentMachines;
    });

    getReasonTemplates = action(async() => {;
        let list:Map<number, string> = new Map();

        try {
            const response = await this.LoginUserStore.client.get("/reasontemplates");
            response.data.items.forEach((value: any) => {
                list.set(value.id, value.title);
            });
        } catch (error) {
            console.error("Error fetching machines:", error);
        }

        this.dictionaryReasonTemplates = list;
    });

    getMachineById = action((id:string): MachinesItem|null => {
        var machine: MachinesItem|null = null;
        for(let i = 0; i < this.machines.length; i++){
            let item = this.machines[i];
            if(item.id === id)
            {
                machine = item;
                break;
            }
        }

        return machine;
    });

    getSensorById = action((id:string): SensorsItem|null => {
        var machine: SensorsItem|null = null;
        for(let i = 0; i < this.machines.length; i++){
            let item = this.machines[i];

            if (item.sensors != null){
                for(let j=0; j < item.sensors.length; j++){
                    let sensor = item.sensors[j];
                    if(sensor.id === id){
                        machine = sensor;
                        break;
                    }
                }
            }

            if(machine != null)
                break;
        }

        return machine;
    });

    setSelectedItem = action((id:string)=>{
        this.calibrationSensorDataSelectedMachine = [];
        this.selectedMachines = id;
        this.getDeviceUUIDById();
        let item = this.getSensorById(id);
        
        if(item){
            this.getSensorsData();
            this.getCalibrationData();
            this.getSelectedInfo();
        }
    });

    getSelectedInfo = action(() => {
        let interval: number|null = null;
        let idReason: number|null = null;
        let machineInfo = this.getMachineById(this.selectedMachines);
        let sensorInfo = this.getSensorById(this.selectedMachines);
        if(machineInfo){
          interval  = machineInfo.notificationInterval;
          idReason = machineInfo.reasonStatusTemplateId;
        } else if (sensorInfo){
          interval = sensorInfo.notificationInterval;
          idReason = sensorInfo.reasonStatusTemplateId;
        }
        this.selectedNotificationInterval = interval;
        this.selectedReasonStatusTemplateId = idReason;
      });

    getDeviceUUIDById = action(()=>{
        let choosenId = this.selectedMachines;
        let uuidDevice = '';
        for(let i = 0; i < this.machines.length; i++){
            let machine = this.machines[i];
            if(machine.id === choosenId){
                if(machine.device != null)
                    uuidDevice  = machine.device.uuid;
                break;
            }

            if(machine.sensors != null){
                for(let j = 0; j < machine.sensors?.length; j++){
                    let sensor = machine.sensors[j];
                    if(sensor.id === choosenId){
                        if(machine.device != null)
                            uuidDevice  = machine.device.uuid;
                        break;
                    }
                }
            }
            
            if(uuidDevice!='')
                break;
        }
        this.selectedUUIDMachine = uuidDevice;
    });

    setSelectedCalibrationData = ((id:string) =>{
        this.selectedIdCalibrationData = id;
    });

    getSensorsData = action(async() =>{
        var sensorItems:SensorsData[]=[];      
        try {
            const response = await this.LoginUserStore.client.get("/sensor_values/" + this.selectedMachines + "?time_zone=" + timezone);
            if(response.data.success == true){
                sensorItems = Object.entries(response.data.items).map(([timestamp, data]:any) => ({
                    name: timestamp,
                    value: data.value,
                    status: data.status
                }));
            } 
        } catch (error) {
            console.error("Error fetching machines:", error);
        }

        this.sensorDataSelectedMachine = sensorItems;
    });

    updateSensorsData = action(async() => {
        var sensorItems:SensorsData[]=[];
        if(this.sensorDataSelectedMachine.length > 0){     
            try {
                const response = await this.LoginUserStore.client.get("/sensor_values/" + this.selectedMachines + "?time_zone="+timezone+"&last_date=" + this.sensorDataSelectedMachine[this.sensorDataSelectedMachine.length-1].name);
                if(response.data.success == true){
                    var sensorData:SensorsData[] = Object.entries(response.data.items).map(([timestamp, data]:any) => ({
                        name: timestamp,
                        value: data.value,
                        status: data.status
                    }));
                    var sensor = this.sensorDataSelectedMachine.splice(sensorData.length);
                    sensorItems = sensor.concat(sensorData);
                }               
            } catch (error) {
                console.error("Error fetching machines:", error);
            }
            this.sensorDataSelectedMachine = sensorItems;
        }      
    });

    getCalibrationData = action(async() => {
        var calibrationList: CalibrationData[] = [];
        try{
            const responseCalibration = await this.LoginUserStore.client.get('/statuses/calibrations/'+ this.selectedMachines);
            
            if(responseCalibration.data.items != null && responseCalibration.data.items.length != 0){
                responseCalibration.data.items.map(async (item: any) => {
                    let calibration:CalibrationData = {
                        id : item.id,
                        paramName: item.param_name,
                        calibrationCondition: item.calibration_condition,
                        valueFrom: item.value_from,
                        valueTo: item.value_to,
                        paramNameX: item.param_name_x,
                        paramNameY: item.param_name_y,
                        paramNameZ: item.param_name_z,
                        warmuptime: item.warmuptime,
                        cooldowntime: item.cooldowntime,
                        paramTitle: item.param_title
                    } ;
                    calibrationList.push(calibration);
                });

                this.setIdCalibration(calibrationList[0].id);
            }
        } catch (error) {
            console.error("Error fetching machines:", error);
        }
        this.calibrationDataSelectedMachine = calibrationList;
    });

    getCalibrationSensorData = action(async()=>{
        const seconds = this.minMinutesCalibration * 60 + this.minSecondsCalibration;
        var calibrationList: CalibrationSensorData[] = [];
        try{
            const responseCalibration = await this.LoginUserStore.client.get('/statuses/'+ this.selectedMachines + '/' + this.selectedIdCalibrationData + '?min=' + seconds);
            if(responseCalibration.data.items != null && responseCalibration.data.items.length != 0){
                responseCalibration.data.items.map(async (item: any) => {
                    let calibration:CalibrationSensorData = {
                        id: item.id,
                        timestampStart: formatTimestamp(item.timestamp_start),
                        timestampEnd: formatTimestamp(item.timestamp_end),
                        duration: item.duration,
                        durationHuman: millisecondsToTime(item.duration),
                        reasonId: item.reason_id,
                        description: item.description,
                        createdAt: item.created_at,
                        updatedAt: item.updated_at,
                        userName: item.user_name,
                        reasonStatus: item.reason_status
                    };
                    calibrationList.push(calibration);
                });
            }
        } catch (error){
            console.error("Error fetching machines:", error);
        }
        this.calibrationSensorDataSelectedMachine = calibrationList;
    })

    setIdCalibration = action((id:string)=>{
        this.selectedIdCalibrationData = id;
        this.getCalibrationSensorData();
    })

    setMinutesCalibration = action ((min:number)=>{
        this.minMinutesCalibration = min;
        this.getCalibrationSensorData();
    })

    setSecondsCalibration = action ((sec:number)=>{
        this.minSecondsCalibration = sec;
        this.getCalibrationSensorData();
    })

    getCalibrationParamName = action ((id:string)=>{
        let str = "";

        for(let i = 0; i < this.calibrationDataSelectedMachine.length; i++){
            let item = this.calibrationDataSelectedMachine[i];
            if(item.id === id){
                str = item.paramTitle=="" ? "Status Value " + item.paramName : item.paramTitle;
                break
            }
        }

        return str;
    })
}