import { observable, action, makeAutoObservable, computed } from 'mobx';
import axiosClient from "../services/axiosInstance";
import axios, { AxiosInstance } from 'axios';
import { LoginUserStore } from './login-user-store';

export interface IUser {
    id: number;
    name: string;
    email: string;  
    is_active: boolean;
    lang: string;
    roles: string[];
  }

export interface IParametersUser {
    name?: string;
    email?: string;  
    password?: string;
    is_active?: boolean;
    lang?: string;
    roles?: string[];
  }

export class  UserStore{
    private loginUserStore:LoginUserStore;
    listUsers:IUser[];
    listRoles:Map<string, string>;  

    constructor(loginUserStore:LoginUserStore) { 
        this.listUsers = [];
        this.listRoles = new Map(); 
        this.loginUserStore = loginUserStore;    
        makeAutoObservable(this, { listUsers: observable, listRoles: observable });
    }

    getUsers = action(async() => {
        let listServerUser: IUser[] = [];
        this.getUserRoles();
        try {
            const response = await this.loginUserStore.client.get("/users");
            response.data.items.forEach((value: any) => {
                let serverUser:IUser = {
                    id: value.id,
                    name: value.name,
                    email: value.email,
                    is_active: value.is_active,
                    lang: value.lang,
                    roles: []
                };
                value.roles.forEach((role: any) =>{
                    serverUser.roles?.push(role)
                });
                listServerUser.push(serverUser);
            });
        } catch (error) {
            console.error("Error fetching user:", error);
        }

        this.listUsers = listServerUser;
    });

    postCreateNewUser = action(async(newUser: IParametersUser) => {
        try {
            const response = await this.loginUserStore.client.post("/users", JSON.stringify(newUser));
            console.log(response.data);
            this.getUsers();
        } catch (error) {
            console.error("Error fetching new user:", error);
            throw new Error((error as Error).message);
        }       
    });

    getUserRoles = action(async() =>{
        let listRole:Map<string, string> = new Map();
        try {
            const response = await this.loginUserStore.client.get("/users/roles");
            if(response.data.success){
                var rolesList:any = Object.entries(response.data.items).map(([name, data]:any) => ({
                    name,
                    data
                }));
                if(rolesList != null){
                    rolesList.forEach((item: any) => {
                        listRole.set(item.name as string, item. data);
                    });
                }
            }
        } catch (error) {
            console.error("Error fetching user roles:", error);
        }
        this.listRoles = listRole;
    })

    getUserInfoById = action(async(id: number) => {
        try {
            const response = await this.loginUserStore.client.get("/users/" + id);
            let user: IUser = {
                id: response.data.id,
                name: response.data.name,
                email: response.data.email,
                is_active: response.data.is_active,
                lang: response.data.lang,
                roles: response.data.roles
            }
            return user;
        } catch (error) {
            console.error("Error fetching user roles:", error);
            throw new Error((error as Error).message);
        }
    })

    postUpdateUser = action(async(userId: number, parameters: IParametersUser) => {
        try{
            const response = await this.loginUserStore.client.post("/users/"+userId,  JSON.stringify(parameters));
            console.log(response.data);
            this.getUsers();
        } catch (error) {
            console.error("Error fetching update user:", error);
            throw new Error((error as Error).message);
        }
    })
}