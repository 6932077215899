import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
  } from '@mui/material';
import '../i18n';
import "./ReasonTemplate.scss";
import { useTranslation } from 'react-i18next'; 
import { observer } from "mobx-react-lite";
import StarterBox from '../asset/components/StarterBox';
import { useStore } from '../hooks/use-store';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ITemplate, ITemplateValues} from "../stores/reason-template-store";
import EditNoteIcon from '@mui/icons-material/EditNote';
import ReasonTemplateDialog from '../asset/components/ReasonTemplateDialog';
import ReasonTemplateValueAddDialog from '../asset/components/ReasonTemplateValueAddDialog';
import ReasonTemplateValueCreateDialog from '../asset/components/ReasonTemplateValueCreateDialog';
import { ClassNames } from '@emotion/react';

function ReasonTemplate(){
    const { t } = useTranslation();
    const { v4: uuidv4 } = require('uuid');
    const machineStore = useStore().reasonTemplateStore;
    const { templates, getTemplates, upTemplateValueInTemplate, downTemplateValueInTemplate, deleteTemplateValueFromTemplate, selectedTemplate, setSelectedTemplate } = machineStore;
    const [openTemplateDialog, setOpenTemplateDialog] = useState<boolean>(false);
    const [openTemplateValueAddDialog, setOpenTemplateValueAddDialog] = useState<boolean>(false);
    const [dialogTemplate, setDialogTemplate] = useState<ITemplate>({id:-1, title:"", items:[]});

    useEffect(() => {
        if(templates.length==0){
            getTemplates();
        }
    }, []);

    const openDialogEditTemplate = (item: ITemplate) => {
        setDialogTemplate(item);
        setOpenTemplateDialog(true);
    }

    const openDialogCreateTemplate = () => {
        setOpenTemplateDialog(true);
    }

    const closeDialogTemplate = () => {
        setOpenTemplateDialog(false);
        setDialogTemplate({id:-1, title:"", items:[]});
    }

    const clickRowReasonsTable = (item: ITemplate) => {
        setSelectedTemplate(item.id);
    }

    const clickArrowUpValue = async(id: number) => {
        await upTemplateValueInTemplate(selectedTemplate.id, id);
    }

    const clickArrowDownValue = async(id: number) => {
        await downTemplateValueInTemplate(selectedTemplate.id, id);
    }

    const clickDeleteTemplateValue = async(id: number) =>{
        await deleteTemplateValueFromTemplate(selectedTemplate.id, id); 
    }

    const openDialogTemplateValueAdd = () => {
        setOpenTemplateValueAddDialog(true);
    }

    const closeDialogTemplateValueAdd = () => {
        setOpenTemplateValueAddDialog(false);
    }

    

    return (
        <StarterBox>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h5">{t("reasons_sinfosy")}</Typography>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left">Template</TableCell>
                                <TableCell align="right"><Button variant='contained' onClick={openDialogCreateTemplate}>New Template</Button></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { templates ? 
                            templates.map((row: ITemplate, idx) => {
                            return (
                            <TableRow key={idx} onClick={() => clickRowReasonsTable(row)} className = {selectedTemplate.id === row.id ? "tableGrey" : "tableWhite"}>
                                <TableCell>{selectedTemplate.id===row.id ? '+' : '-'}</TableCell>
                                <TableCell align="left">{row.title}</TableCell>
                                <TableCell align="right"><EditNoteIcon onClick={() => openDialogEditTemplate(row)}/></TableCell>
                            </TableRow>)
                            }) : <TableRow key={uuidv4()}/>
                        }
                        </TableBody>
                    </Table>
                    <ReasonTemplateDialog onClickButtonClose={closeDialogTemplate} isOpen={openTemplateDialog} template={dialogTemplate}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5">{t("template_values")}</Typography>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left">Value</TableCell>
                                <TableCell align="right">
                                    {selectedTemplate.id !== -1 ? <Button variant='contained' className='button-margin' onClick={openDialogTemplateValueAdd}>Add</Button> : ""}
                                    {/*<Button variant='contained' className='button-margin' onClick={openDialogTemplateValueCreate}>Create</Button>*/}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { selectedTemplate.items.map((row: ITemplateValues, idx) => {
                            return (
                            <TableRow key={idx}>
                                <TableCell>{idx !== 0 ? <ArrowUpwardIcon onClick={() => clickArrowUpValue(row.id)}/> : <ArrowUpwardIcon sx={{color:'white'}}/>}{idx!==selectedTemplate.items.length-1 ? <ArrowDownwardIcon onClick={()=>clickArrowDownValue(row.id)}/> : ''}</TableCell>
                                <TableCell align="left">{row.title}</TableCell>
                                <TableCell align="right"><Button onClick={() => clickDeleteTemplateValue(row.id)} className="deleteButton">Delete</Button></TableCell>
                            </TableRow>)
                            }) 
                        }
                        </TableBody>
                    </Table>
                    <ReasonTemplateValueAddDialog onClickButtonClose={closeDialogTemplateValueAdd} isOpen={openTemplateValueAddDialog} template={selectedTemplate}/>
                    
                </Grid>
            </Grid>
        </StarterBox>
    );
}

export default observer(ReasonTemplate);