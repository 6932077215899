import React from "react";
import { NotificationItem } from '../../stores/machine-store-interfaces';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox
  } from '@mui/material'; 
import { useStore } from '../../hooks/use-store';
import { useTranslation } from 'react-i18next';

interface DialogNotificationProps {
    row: NotificationItem|null;
    open: number|null;
    onClickButtonClose: (e: any) => void;
    index:number|null;
    isOpen:boolean;
  }

function DialogNotification({ row, open, onClickButtonClose, index, isOpen }:DialogNotificationProps) {
    const machineStore = useStore().machineStore;
    const { getCalibrationParamName } = machineStore;
    const { t } = useTranslation();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let email = data.get('email');
      let status = data.get('status');
      let isEmail = data.get('is_email');
      let isPush = data.get('is_push');
      let sendOnce = data.get('send_once');
      let emailPrefix = data.get('email_prefix');
  
      if(email === "" || status === "")
      {
        console.log("empty");
      }
      else
      {
        console.log(status + " " + email + " " + isEmail + " " + isPush + " " + sendOnce + " " + emailPrefix); 
      }
    };

    return (
        <Dialog open={ (open != null && open === index) || isOpen }
        onClose={onClickButtonClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            handleSubmit(event);
            onClickButtonClose(event);
          },
        }}>
            <DialogTitle>{row != null ? 'Edit' : 'Add'} notification</DialogTitle>
            <DialogContent>
              <TextField
                margin="normal"
                fullWidth
                required
                id="status"
                label="Status"
                name="status"
                autoFocus
                variant='filled'
                defaultValue = {row != null ? getCalibrationParamName(row.calibrationId) : ""}
              />
              <TextField
                margin="normal"
                fullWidth
                required
                id="email"
                label="Email"
                name="email"
                autoFocus
                variant='filled'
                defaultValue = {row != null ? row.userEmail : ""}
              />
              <FormControlLabel
                control={<Checkbox defaultChecked={row != null && row.isEmail == 1 ? true : false} id="is_email" name="is_email"/>}
                label="E"
                labelPlacement="start"               
              />
              <FormControlLabel
                control={<Checkbox id="is_push" name="is_push" defaultChecked={row != null && row.isPush == 1 ? true : false}/>}
                label="P"
                labelPlacement="start"            
              />
              <FormControlLabel
                control={<Checkbox id="send_once" name="send_once" defaultChecked={row != null ? row.isSendOnce : false}/>}
                label={t("send_once")}
                labelPlacement="start"
              />
              <TextField
                margin="normal"
                fullWidth
                id="email_prefix"
                label={"Email " + t("prefix")}
                name="email_prefix"
                autoFocus
                variant='filled'
                defaultValue = {row != null ? row.emailPrefix : ""}
              />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClickButtonClose}>Cancel</Button>
                <Button type="submit">{row != null ? "Save" : "Create"}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogNotification;

