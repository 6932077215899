import Grid from '@mui/material/Grid';
import { styled } from '@mui/material'; 

export const LeftGridItem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '28%',
    },
    [theme.breakpoints.up('md')]: {
      width: '16.6%',
    },
  })) as typeof Grid;

  export const RightGridItem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '68%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80.8%',
    },
  })) as typeof Grid;

  export const LabelGrid = styled(Grid)(({ theme }) => ({
    width: "150px",
    display: 'flex',
  })) as typeof Grid;

  export const LabelGridMachine = styled(Grid)(({ theme }) => ({
    display: 'flex',
  })) as typeof Grid;