import React, {useEffect} from 'react';
import './Devices.scss';
import '../i18n';
import { useTranslation } from 'react-i18next';
import StarterBox from '../asset/components/StarterBox';
import DeviceTableRow from '../asset/components/DeviceTableRow';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper
} from "@mui/material";
import { useStore } from '../hooks/use-store';
import { observer } from "mobx-react-lite";
import { DeviceItem } from '../stores/device-store';

function Devices() {
  const deviceStore = useStore().deviceStore;
  const { devices, setDevices} = deviceStore;
  const { t } = useTranslation();
  

  useEffect(() =>{
    if(devices.length==0){
      setDevices();
    }
   }, []);

  const refreshTable = (e:any) =>
  {
    setDevices();
  };

  return (
    <StarterBox>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Typography variant="h5">{t("devices")}</Typography>
        </Grid>
        <Grid item>
          <AutorenewIcon onClick={refreshTable}/>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className='table-container'>
        <Table aria-label="device table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='center'>{t("assigned_machine")}</TableCell>
              <TableCell align='center'>{t("last_seen_device")}</TableCell>
              <TableCell align='center'>{t("app")}</TableCell>
              <TableCell align='center'>{t("battery")}</TableCell>
              <TableCell align='center'>{t("flash")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              devices.map((item:DeviceItem)=>{
                return <DeviceTableRow key={item.uuid} items ={item}/>
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </StarterBox>
  );
}

export default observer(Devices);