import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl,
    NativeSelect,
    Button,
  } from '@mui/material'; 
import '../../pages/Machines.scss';
import { useStore } from '../../hooks/use-store';
import { observer } from "mobx-react-lite";
import MachineChart from './MachineChart';
import StatusLogTable from './StatusLogTable';
import MachineDeviceTable from './MachineDeviceTable';
import { useTranslation } from 'react-i18next';
import { SensorsItem, CalibrationData, NotificationItem } from '../../stores/machine-store-interfaces';
import MachineDialogNotification from './MachineDialogNotification';
import SensorInfoRow from "./SensorInfoRow";

interface SensorBoxProps {
    sensorInfo: SensorsItem;
}

const SensorBox = ({ sensorInfo }: SensorBoxProps) =>  {
    const { v4: uuidv4 } = require('uuid');
    const { t } = useTranslation();
    const machineStore = useStore().machineStore;
    const { calibrationDataSelectedMachine, selectedNotificationInterval, selectedReasonStatusTemplateId, dictionaryReasonTemplates, calibrationSensorDataSelectedMachine } = machineStore;

    const [dialogAddNotification, setDialogAddNotification] = useState<boolean>(false);

    const openDialogAddNotification = () => {
        setDialogAddNotification(true);
    }
    
    const closeDialogAddNotification = () => {
        setDialogAddNotification(false);
    }

    return(
        <Box> 
            <MachineDeviceTable />
            <MachineChart /> 
            { calibrationSensorDataSelectedMachine.length > 0 ?<StatusLogTable /> : ""}
            { calibrationDataSelectedMachine.length > 0 ? <Box>
            <Typography variant="h6" className="table-box-machine">{t("calibration")} :</Typography>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("status")}</TableCell>
                        <TableCell align="center">{t("condition")}</TableCell>
                        <TableCell align="center">{t("warmup")}</TableCell>
                        <TableCell align="center">{t("cooldown")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                { calibrationDataSelectedMachine.map((row: CalibrationData, idx) => (
                    <TableRow key={idx} id={row.id}>
                        <TableCell>{row.paramTitle=="" ? "Status Value " + row.paramName : row.paramTitle}</TableCell>
                        <TableCell align="center">{row.valueFrom === "-9999999"? "<" + row.valueTo : row.valueFrom + "<"}</TableCell>
                        <TableCell align="center">{row.warmuptime}</TableCell>
                        <TableCell align="center">{row.cooldowntime}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table></Box> : ""}
            <Typography className="table-box-machine" variant="h6" >{t("notification")} {t("interval")}:</Typography>
            <Box className="table-box-machine">
                <FormControl fullWidth>
                    <NativeSelect value={selectedNotificationInterval ? selectedNotificationInterval : 0}>
                    {
                        selectedNotificationInterval ? <option key={uuidv4()} value={selectedNotificationInterval}>{selectedNotificationInterval}</option> : ""
                    }
                    </NativeSelect>
                </FormControl>
            </Box>
            <Box>
                <Typography   variant="h6" className="table-box-machine notification-box">{t("notification")}:</Typography>
                <Button sx ={{float: "right",display:'inline'}} size="medium" variant="contained" onClick={openDialogAddNotification}>Add</Button>
                <MachineDialogNotification row={null} open={null} onClickButtonClose={closeDialogAddNotification} isOpen={dialogAddNotification} index={null}/>
            </Box>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("status")}</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">E</TableCell>
                        <TableCell align="center">P</TableCell>
                        <TableCell align="center">{t("send_once")}</TableCell>
                        <TableCell align="center">Email {t("prefix")}</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                { sensorInfo ? sensorInfo.notifications.map((row: NotificationItem, idx) => {
                    return <SensorInfoRow row={row} idx={idx}/>;
          }) : <TableRow key={uuidv4()}/>
          }
                </TableBody>
            </Table>
            <Typography variant="h6" className="table-box-machine">{t("incident_reason")}:</Typography>
            <Box className="table-box-machine">
                <FormControl fullWidth>
                    <NativeSelect value={selectedReasonStatusTemplateId != null ? selectedReasonStatusTemplateId : -1}>
                    {
                        selectedReasonStatusTemplateId != null ? <option key={uuidv4()} value={selectedReasonStatusTemplateId}>{dictionaryReasonTemplates.has(selectedReasonStatusTemplateId) ? dictionaryReasonTemplates.get(selectedReasonStatusTemplateId) : selectedReasonStatusTemplateId}</option> : ""
                    } 
                    </NativeSelect> 
                </FormControl>
            </Box>
        </Box>
    );
}

export default observer(SensorBox);