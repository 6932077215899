import {
    Box,
    Typography,
    CircularProgress
  } from '@mui/material'; 
import '../../pages/Machines.scss';

function MachineLoadingCircle(){
    return (
        <Box className="machine-loading-box">
                <Box className="machine-loading-container">
                  <CircularProgress />
                  <Typography sx={{pt:1,pl:"8px"}}>Loading</Typography>
                </Box>        
              </Box>
    );
}

export default MachineLoadingCircle;