import { observable, action, makeAutoObservable, computed } from 'mobx';
import axiosClient from "../services/axiosInstance";
import axios, { AxiosInstance } from 'axios';

export interface ILoginUser {
    bearerToken: string;
    email: string;
}

export class  LoginUserStore{
    private loginUser: ILoginUser = { bearerToken: "", email: ""};

    constructor() { ;     
        makeAutoObservable(this, { getLoginUser: computed, client: computed });
    }

    setLoginUser = action((token: any, email: any) => {
        
        this.loginUser = { bearerToken: token, email: email };
    });
  
    logout = action(() => {
        this.loginUser = { bearerToken: "", email: ""};
    });

    get getLoginUser () {
        return this.loginUser;
    }

    get client(): AxiosInstance {
        return axiosClient(this.loginUser.bearerToken);
    }
}