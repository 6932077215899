import Directions from '../enums/enum-direction';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import './NavBar.scss';
import { useLocation } from 'react-router-dom';

export interface HamburgerInterface{
    label: string; 
    icon: JSX.Element; 
    href: Directions|null;
  }

interface NavBarMenuItemProps {
    item: HamburgerInterface;
    onClickButton: (e: any, href: any) => void;
  }

const NavBarMenuItem = ({item, onClickButton}:NavBarMenuItemProps)=>{
  const location = useLocation();
  return (
    <MenuItem key={item.label} onClick={(event) => onClickButton(event, item.href)} className={ item.href===location.pathname ? 'list-item-select' : 'menu-item'}>
      <ListItemIcon className={ item.href===location.pathname ? 'list-icon-select' : ''}>
        {item.icon}
      </ListItemIcon>
      <ListItemText>{item.label}</ListItemText>                     
    </MenuItem>
  );
}

export default NavBarMenuItem;