import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import {initReactI18next} from "react-i18next";

const fallbackLng = "en";

i18n
    .use(Backend) //for loading data from other directory
    .use(LanguageDetector) // detects the current language
    .use(initReactI18next) // Initializes the react-i18next plugin.
    .init({
    supportedLngs: ['en', 'de'], // Languages we're supporting.
    fallbackLng: "en", // Fallback language if user's language isn't supported.
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'], // Order of language detection.
      caches: ['cookie'], // Cache the detected language in cookies.
    },
    backend: {
        loadPath: "/locales/{{lng}}/translation.json", // Path to the translation files.
    },
    //debug: true,
  });

export default i18n;