import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Typography
  } from '@mui/material'; 
import { useStore } from '../../hooks/use-store';
import { useTranslation } from 'react-i18next';
import { ITemplate } from "../../stores/reason-template-store";

interface ReasonTemplateDialogProps {
    onClickButtonClose: (e: any) => void;
    template:ITemplate;
    isOpen:boolean;
  }

function ReasonTemplateDialog({ onClickButtonClose, template, isOpen }:ReasonTemplateDialogProps) {
    const reasonTemplateStore = useStore().reasonTemplateStore;
    const { createTemplate, editTemplate } = reasonTemplateStore;
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [titleText, setTitleText] = useState<string>("");

    useEffect(() =>{
        setTitleText(template.title);
        setErrorMessage("");
    }, [isOpen]);  

    const onClickButtonEdit = async(event: any) => {
        try {
            await editTemplate(template.id, titleText);
            onClickButtonClose(event);
          } catch(error) {
            setErrorMessage((error as Error).message);
          }
    };

    const onClickButtonCreate = async(event: any) => {
        try {
            await createTemplate(titleText);
            onClickButtonClose(event);
          } catch(error) {
            setErrorMessage((error as Error).message);
          }
    };

    return (
        <Dialog open={ isOpen }
            onClose={onClickButtonClose}
            PaperProps={{
            component: 'form',
        }}>
            <DialogTitle>{template.id!==-1? 'Edit' : 'Add'} template</DialogTitle>
            <DialogContent>
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="Title"
                  variant='filled'
                  name='title'
                  value={titleText}
                  onChange={(e) => {
                    setTitleText(e.target.value);
                  }}
                />
            </DialogContent>
            <DialogActions>
              <Typography variant="body2" color="#FF0000">{errorMessage}</Typography>
              <Button onClick={onClickButtonClose}>Cancel</Button>
              <Button onClick={template.id!==-1 ? onClickButtonEdit: onClickButtonCreate}>{template.id!==-1 ? "Save" : "Create"}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReasonTemplateDialog;