import { observable, action, makeAutoObservable, computed } from 'mobx';
import { LoginUserStore } from './login-user-store';

export interface IMachineRulesUser {
    id: number;
    name: string;
    email: string;
}

export interface IMachineRulesMachine {
    id: string;
    name: string;
    description: string;
    isSelected: boolean;
}

export interface IMachineRulesCalibration{
    id: string;
    paramName: string;
    paramTitle: string;
    isSelected: boolean;
}

export interface IMachineRulesSensor{
    id: string;
    sensorType: string;
    calibrations: IMachineRulesCalibration[];
}

export class MachineRulesStore{
    private loginUserStore:LoginUserStore;
    listUsers: IMachineRulesUser[]; 

    constructor(loginUserStore:LoginUserStore) { 
        this.loginUserStore = loginUserStore;
        this.listUsers = [];
        makeAutoObservable(this, { listUsers: observable });
    }

    getListUser = action (async() => {
        let users: IMachineRulesUser[] = [];
        try{
            const response = await this.loginUserStore.client.get("/machinerules/users");
            console.log(response.data);
            response.data.users.forEach((value: any) => {
                let template:IMachineRulesUser = {
                    id: value.id,
                    email: value.email,
                    name: value.name
                };
                
                users.push(template);
            });      
        } catch(error){
            console.error("Error fetching users:", error);
        } 

        this.listUsers = users; 
        return users;
    })

    getMachinesByUserId = action (async( id: number ) => {
        let machines: IMachineRulesMachine[] = [];

        try{
            const response = await this.loginUserStore.client.get("/machinerules/users/"+id);
            console.log(response.data);
            response.data.machines.forEach((value: any) => {
                let template:IMachineRulesMachine = {
                    id: value.id,
                    name: value.name,
                    description: value.description,
                    isSelected: value.is_selected
                };
                
                machines.push(template);
            });    
        } catch(error){
            console.error("Error fetching machine:", error);
        }

        return machines;
    })

    setGrantAccessMachine = action (async(idUser: number, idMachine: string) => {
        try{
            const response = await this.loginUserStore.client.post("/machinerules/users/"+idUser+"/"+idMachine);
            console.log(response.data);   
        } catch(error){
            console.error("Error grant machine:", error);
        }
    })

    setDisableAccessMachine = action (async(idUser: number, idMachine: string) => {
        try{
            const response = await this.loginUserStore.client.delete("/machinerules/users/"+idUser+"/"+idMachine);
            console.log(response.data);   
        } catch(error){
            console.error("Error delete machine:", error);
        }
    })

    getSensorCalibtaionList = action (async(idUser: number, idMachine: string) => {
        let listSensors: IMachineRulesSensor[] = [];
        try{
            const response = await this.loginUserStore.client.get("/machinerules/users/"+idUser+"/"+idMachine);
            console.log(response.data);   
            response.data.sensors.forEach((value: any) => {
                let sensor: IMachineRulesSensor = {
                    id: value.id,
                    sensorType: value.sensor_type,
                    calibrations: []
                };

                value.calibrations.forEach((calibration: any) => {
                    let calibr: IMachineRulesCalibration = {
                        id: calibration.id,
                        paramName: calibration.param_name,
                        paramTitle: calibration.param_title,
                        isSelected: calibration.is_selected
                    };
                    sensor.calibrations.push(calibr);
                })

                listSensors.push(sensor);
            })
        } catch(error){
            console.error("Error delete machine:", error);
        }
        return listSensors;
    })

    setGrantAccessCalibration = action (async(idUser: number, idMachine: string, idCalibration: string) => {
        try{
            const response = await this.loginUserStore.client.post("/machinerules/users/"+idUser+"/"+idMachine+"/"+idCalibration);
            console.log(response.data);   
        } catch(error){
            console.error("Error grant machine:", error);
        }
    })

    setDisableAccessCalibration = action (async(idUser: number, idMachine: string, idCalibration: string) => {
        try{
            const response = await this.loginUserStore.client.delete("/machinerules/users/"+idUser+"/"+idMachine+"/"+idCalibration);
            console.log(response.data);   
        } catch(error){
            console.error("Error delete machine:", error);
        }
    })
}