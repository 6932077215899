import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Switch,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
  } from '@mui/material';
import '../i18n';
import "./UserMachines.scss";
import { useTranslation } from 'react-i18next'; 
import { observer } from "mobx-react-lite";
import StarterBox from '../asset/components/StarterBox';
import { useStore } from '../hooks/use-store';
import { IMachineRulesUser, IMachineRulesMachine, IMachineRulesSensor, IMachineRulesCalibration } from '../stores/machine-rules-store';

function UserMachinesSensors(){
    const { t } = useTranslation();
    const { v4: uuidv4 } = require('uuid');
    const machineRules = useStore().machineRulesStore;
    const { listUsers, getListUser, getMachinesByUserId, getSensorCalibtaionList, setDisableAccessCalibration, setGrantAccessCalibration } = machineRules;
    const [ selectedUser, setSelectedUser ] = useState<number>(-1);
    const [ selectedMachines, setSelectedMachines ] = useState<IMachineRulesMachine[]>([]);
    const [ selectedMachineId, setSelectedMachineId ] = useState<string>("");
    const [ selectedSensors, setSelectedSensors ] = useState<IMachineRulesSensor[]>([]);


    useEffect(() => {
        if(listUsers.length==0){
            beforeDownload();
        }
    }, []);

    const beforeDownload = async() => {
        let list = await getListUser();
        if(list.length>0){
            setSelectedUser(list[0].id);
            let machines = await getMachinesByUserId(list[0].id);
            setSelectedMachines(machines);
            if(machines.length > 0){
                await clickRowMachineTable(machines[0].id);
            }
        }
    }

    const clickRowUsersTable = async(id: number) => {
        setSelectedUser(id);
        let machines = await getMachinesByUserId(id);
        setSelectedMachines(machines);
        setSelectedMachineId("");
        if(machines.length > 0){
            clickRowMachineTable(machines[0].id);
        }
        setSelectedSensors([]);
    }

    const clickRowMachineTable = async(id: string) => {
        setSelectedSensors([]);
        setSelectedMachineId(id);
        setSelectedSensors(await getSensorCalibtaionList(selectedUser, id));
    }

    const clickRowCalibrationTable = async(calibration: IMachineRulesCalibration) => {
        console.log(calibration.isSelected);
        if(calibration.isSelected)
            await setDisableAccessCalibration( selectedUser, selectedMachineId, calibration.id );
        else
            await setGrantAccessCalibration(selectedUser, selectedMachineId, calibration.id);
        setSelectedSensors(await getSensorCalibtaionList(selectedUser, selectedMachineId));
    }

    return(
        <StarterBox>
            <Typography variant="h5">{t("user_access")}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                <TableContainer component={Paper} className='table-height'>
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left"><b>Users</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { listUsers.length > 0 ? 
                            listUsers.map((row: IMachineRulesUser, idx) => {
                            return (
                            <TableRow key={idx} onClick={() => clickRowUsersTable(row.id)} className = {selectedUser === row.id ? "tableGrey" : "tableWhite"}>
                                <TableCell>{selectedUser===row.id ? '+' : '-'}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                            </TableRow>)
                            }) : <TableRow key={uuidv4()}/>
                        }
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={3}>
                <TableContainer component={Paper} className='table-height'>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left"><b>Machines</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { selectedMachines.length > 0 && selectedUser != -1? 
                            selectedMachines.map((row: IMachineRulesMachine, idx) => {
                            return (
                            <TableRow key={idx} onClick={() => clickRowMachineTable(row.id)} className = {selectedMachineId === row.id ? "tableGrey" : "tableWhite"}>
                                <TableCell>{selectedMachineId === row.id ? '+' : '-'}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                            </TableRow>)
                            }) : <TableRow key={uuidv4()}/>
                        }
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={6}>
                <TableContainer component={Paper} className='table-height'>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left"><b>Sensors</b></TableCell>
                                <TableCell align="left"><b>Status</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {selectedSensors.length > 0 && selectedMachineId !== "" ? 
                            selectedSensors.map((sensor, sensorIndex) => (
                            <React.Fragment key={sensorIndex}>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="left">{sensor.sensorType}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                {sensor.calibrations.map((calibration, calibrationIndex) => (
                                    <TableRow key={calibrationIndex}>
                                        <TableCell><Switch checked={calibration.isSelected} onChange={() => clickRowCalibrationTable(calibration)} /></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="left">Status Value {calibration.paramName}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))  : <TableRow key={uuidv4()}/>
                        }
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </StarterBox>
    )
}

export default observer(UserMachinesSensors);