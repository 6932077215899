import { createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#0046A3',
        contrastText: 'white'
      },
      background:{
        default: 'red'
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color:'#29b4de',
            borderColor:'#29b4de',
            backgroundColor: '#bedee8'
          },
          outlined:
          {
            color:'#29b4de',
            borderColor:'#29b4de',
            backgroundColor: '#f7fdff',
            '&:hover': {
                color:'#29b4de',
                borderColor:'#29b4de',
                backgroundColor: '#dceff5',
            }
          }
        },
      },
      MuiBreadcrumbs:
      {
        styleOverrides:
        {
          separator: {
            color: 'white',
            fontSize: '25px',
            position: 'relative', 
          },
          root: {
            color: 'white',
            fontSize: '20px',
            position: 'relative', 
            fontWeight: 'bold'
          },
        }
      }
    },
  });

export { theme };