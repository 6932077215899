import { UserStore } from './user-store';
import { MachineStore } from './machine-store';
import { DeviceStore } from './device-store';
import { LoginUserStore } from './login-user-store'; 
import { ReasonStoreStore } from './reason-template-store';
import { MachineRulesStore } from './machine-rules-store';

export class RootStore{
    loginUserStore: LoginUserStore;
    userStore:UserStore;
    machineStore: MachineStore;
    deviceStore: DeviceStore;
    reasonTemplateStore: ReasonStoreStore;
    machineRulesStore: MachineRulesStore;

    constructor(){
        this.loginUserStore = new LoginUserStore();
        this.userStore = new UserStore(this.loginUserStore);
        this.machineStore = new MachineStore(this.loginUserStore);
        this.deviceStore = new DeviceStore(this.loginUserStore);
        this.reasonTemplateStore = new ReasonStoreStore(this.loginUserStore);
        this.machineRulesStore = new MachineRulesStore(this.loginUserStore);;;
    }
}

export const store = new RootStore();