import { observable, action, makeAutoObservable, computed } from 'mobx';
import { LoginUserStore } from './login-user-store';

export interface ITemplateValues{
    id: number;
    title: string;
}

export interface IValue{
    id: number;
    title: string;
    title_alt: string;
}

export interface ICreateValue{
    title: string;
    title_alt: string;
}

export interface ITemplate{
    id: number;
    title: string;
    items: ITemplateValues[];
}

export class  ReasonStoreStore{
    private loginUserStore:LoginUserStore; 
    templates: ITemplate[];
    values:IValue[];
    selectedTemplate:ITemplate;

    constructor(loginUserStore:LoginUserStore) { 
        this.loginUserStore = loginUserStore;
        this.templates = [];    
        this.values = [];
        this.selectedTemplate = {id:-1, title:"", items:[]};
        makeAutoObservable(this, { templates: observable, selectedTemplate: observable });
    }

    getTemplates = action(async()=>{
        let listTemplates: ITemplate[] = [];
        this.getTemplateValues();
        try{
            const response = await this.loginUserStore.client.get("/reasontemplates");
            response.data.items.forEach((value: any) => {
                let template:ITemplate = {
                    id: value.id,
                    title: value.title,
                    items: []
                };
                value.items.map((item: any) => {
                    let valueTemplate:ITemplateValues ={
                        id: item.id,
                        title: item.title,
                    };
                    template.items.push(valueTemplate); 
                });
                listTemplates.push(template);
            });

            if(this.selectedTemplate.id !== -1){
                for (let i = 0; i < listTemplates.length; i++){
                    if(this.selectedTemplate.id===listTemplates[i].id){

                        this.selectedTemplate = listTemplates[i];
                        break;
                    }
                }
            }
            
        } catch(error){
            console.error("Error fetching templates:", error);
        } 

        this.templates = listTemplates;
    });

    setSelectedTemplate = action((id:number)=>{
        for (let i = 0; i < this.templates.length; i++){
            if(id===this.templates[i].id){
                this.selectedTemplate = this.templates[i];
                break;
            }
        }
    })

    createTemplate = action(async(title:string)=>{
        try{
            const response = await this.loginUserStore.client.post("/reasontemplates",  JSON.stringify({title: title}));
            console.log(response.data);
            this.getTemplates();
        }catch (error) {
            console.error("Error fetching create template:", error);
            throw new Error((error as Error).message);
        }
    })

    editTemplate = action(async(id:number, title:string)=>{
        try{
            const response = await this.loginUserStore.client.post("/reasontemplates/"+id,  JSON.stringify({title: title}));
            console.log(response.data);
            this.getTemplates();
        }catch (error) {
            console.error("Error fetching edit template:", error);
            throw new Error((error as Error).message);
        }
    })

    addTemplateValueToTemplate = action(async(templateId: number, tempalateValueId: number)=>{
        try{
            const response = await this.loginUserStore.client.post("/reasontemplates/"+templateId+"/"+tempalateValueId,  JSON.stringify({action: 'add'}));
            console.log(response.data);
            this.getTemplates();
        }catch (error) {
            console.error("Error fetching add value to template:", error);
            throw new Error((error as Error).message);
        }
    })

    deleteTemplateValueFromTemplate = action(async(templateId: number, tempalateValueId: number)=>{
        try{
            const response = await this.loginUserStore.client.post("/reasontemplates/"+templateId+"/"+tempalateValueId,  JSON.stringify({action: 'delete'}));
            console.log(response.data);
            this.getTemplates();
        }catch (error) {
            console.error("Error fetching delete value to template:", error);
            //throw new Error((error as Error).message);
        }
    })
    
    upTemplateValueInTemplate = action(async(templateId: number, tempalateValueId: number)=>{
        try{
            const response = await this.loginUserStore.client.post("/reasontemplates/"+templateId+"/"+tempalateValueId,  JSON.stringify({action: 'up'}));
            console.log(response.data);
            this.getTemplates();
        }catch (error) {
            console.error("Error fetching up value to template:", error);
            //throw new Error((error as Error).message);
        }
    })

    downTemplateValueInTemplate = action(async(templateId: number, tempalateValueId: number)=>{
        try{
            const response = await this.loginUserStore.client.post("/reasontemplates/"+templateId+"/"+tempalateValueId,  JSON.stringify({action: 'down'}));
            console.log(response.data);
            this.getTemplates();
        }catch (error) {
            console.error("Error fetching down value to template:", error);
            //throw new Error((error as Error).message);
        }
    })

    createNewValues = action(async(title: string, title_alt: string)=>{
        try{
            const response = await this.loginUserStore.client.post("/reasontemplatevalues",  JSON.stringify({title: title, title_alt: title_alt}));
            console.log(response.data);
            this.getTemplateValues();
            return response.data.id;
        }catch (error) {
            console.error("Error fetching create value:", error);
            throw new Error((error as Error).message);
        }
    })

    deleteValueFromDB = action(async(tempalteValueId: number)=>{
        try{
            const response = await this.loginUserStore.client.delete("/reasontemplatevalues/"+tempalteValueId);
            console.log(response.data);
            this.getTemplateValues();
        }catch (error) {
            console.error("Error fetching delete value to template:", error);
            throw new Error((error as Error).message);
        }
    })

    getTemplateValues = action(async() => {
        let values:IValue[]=[];
        try{
            const response = await this.loginUserStore.client.get("/reasontemplatevalues");
            response.data.items.forEach((value: any) =>{
                values.push(
                    {
                        id: value.id,
                        title: value.title,
                        title_alt: value.title_alt
                    }
                );
            });
        }catch (error) {
            console.error("Error fetching get values from db:", error);
            throw new Error((error as Error).message);
        }
        this.values = values;
    })
}