import React, {useEffect, useState, useRef} from 'react';
import './Machines.scss';
import '../i18n';
import { useTranslation } from 'react-i18next';
import StarterBox from '../asset/components/StarterBox';
import MachineList from '../asset/components/MachineList';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'; 
import { useStore } from '../hooks/use-store';
import { observer } from "mobx-react-lite";
import { LeftGridItem, RightGridItem, LabelGrid } from './MachinesStyleMUI';
import useScreenSize from '../hooks/use-screen-size';
import MachineModalWindow from '../asset/components/MachineModalWindow';
import MachineBox from '../asset/components/MachineBox';
import MachineLoadingCircle from '../asset/components/MachineLoadingCircle';

const sizeForOpenDrawer = 640;

const Machines = observer(() => {
  const machineStore = useStore().machineStore;
  const { machines, setMachines, showLoagingBar} = machineStore;
  const {setDevices} = useStore().deviceStore;
  const { t } = useTranslation();
  const screenSize = useScreenSize();

  useEffect(() => {
    if(machines.length==0){
      setDevices();
      setMachines();
    }
  }, []);

  const refreshTable = (e:any) =>
  {
    setMachines();
  };

  return (
    <StarterBox>
      <Grid container alignItems="center" >
        {
          screenSize.width > sizeForOpenDrawer ? (<LabelGrid item><Typography variant='h5'>{t("machines")}</Typography></LabelGrid>) : 
          <MachineModalWindow/>
        }      
        <Grid item>
          <AutorenewIcon onClick={refreshTable}/>
        </Grid>
      </Grid>    
      {
        screenSize.width > sizeForOpenDrawer ? 
          <Grid container>
            <LeftGridItem item className='machine-grid-left'>  
            {
              !showLoagingBar? <MachineList/> : <MachineLoadingCircle/>          
            }                 
            </LeftGridItem>
            <RightGridItem item className='machine-grid-right'>             
              <MachineBox/>
            </RightGridItem> 
          </Grid> : 
          <Box className='machine-grid-right'>
            <MachineBox />
          </Box>
      }       
    </StarterBox>
  );
});

export default Machines;