import React, {useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box
} from '@mui/material';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../hooks/use-store';
import { DeviceItem } from '../../stores/device-store';
import { observer } from "mobx-react-lite";
import { DeviceButtonAction } from "../../stores/device-store";

function MachineDeviceTable(){
    const deviceStore = useStore().deviceStore;
    const machineStore = useStore().machineStore;
    const { getDeviceById, setDeviceActionFlash, setDeviceActionReload, setDeviceActionLogSet, setDeviceActionLogSetOff} = deviceStore;
    const { selectedUUIDMachine } = machineStore;
    const { t } = useTranslation();
    const [currentDevice, setCurrentDevice] = React.useState<DeviceItem|null>(null);
    const [disabledFlash, setDisabledFlash] = React.useState<boolean>(false);
    const [disabledReload, setDisabledReload] = React.useState<boolean>(false);
    const [disabledSetLog, setDisabledSetLog] = React.useState<boolean>(false);
    const [disabledSetLogOff, setDisabledSetLogOff] = React.useState<boolean>(false);

    useEffect(( )=>{
        let device = getDeviceById(selectedUUIDMachine);
        setCurrentDevice(device);
    },[selectedUUIDMachine]);

    const onClickButtonFlash = async(e:any) =>{
        setDisabledFlash(true);
        await setDeviceActionFlash(e.target.id);
        setDisabledFlash(false);
      };

      const onClickButtonReload = async(e:any) =>{
        setDisabledReload(true);
        await setDeviceActionReload(e.target.id);
        setDisabledReload(false);
      };

      const onClickButtonLogSet = async(e:any) =>{
        setDisabledSetLog(true);
        await setDeviceActionLogSet(e.target.id);
        setDisabledSetLog(false);
      };

      const onClickButtonLogSetOff = async(e:any) =>{
        setDisabledSetLogOff(true);
        await setDeviceActionLogSetOff(e.target.id);
        setDisabledSetLogOff(false);
      };

    return(
        <TableContainer  component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("device_info")}</TableCell>
                        <TableCell align="center">{t("last_seen_device")}</TableCell>
                        <TableCell align="center">{t("app")}</TableCell>
                        <TableCell align="center">{t("battery")}</TableCell>
                        <TableCell align="right">{t("flash")}</TableCell>
                        <TableCell align="right">{t("reload")}</TableCell>
                        <TableCell align="center">{t("logs")} {t("on")}/{t("off")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { currentDevice != null ?
                        <TableRow
                            key={currentDevice.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{currentDevice.name}</TableCell>
                            <TableCell align="center">{currentDevice.lastSeen}</TableCell>
                            <TableCell align="center">{currentDevice.appVersion}</TableCell>
                            <TableCell align="center">{currentDevice.batteryPercent}</TableCell>
                            <TableCell align="right"><Button id={currentDevice.uuid} onClick={onClickButtonFlash} disabled = {disabledFlash}>{t("flash")}</Button></TableCell>
                            <TableCell align="right"><Button id={currentDevice.uuid} onClick={onClickButtonReload} disabled = {disabledReload}>{t("reload")}</Button></TableCell>
                            <TableCell align="center"><Button id={currentDevice.uuid} onClick={onClickButtonLogSet} disabled = {disabledSetLog}>{t("on")}</Button>/
                            <Button id={currentDevice.uuid} onClick={onClickButtonLogSetOff} disabled = {disabledSetLogOff}>{t("off")} </Button></TableCell>
                        </TableRow> : <TableRow/>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default observer(MachineDeviceTable);