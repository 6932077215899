import { createTheme, ThemeProvider, makeStyles } from '@mui/material/styles';

  const theme = createTheme({
    palette: {
      primary: {
        main: '#29b4de',
        contrastText: '#ffffff'
      },
      secondary: {
        //main: '#f50057',
        main: '#29b4de',
        contrastText: '#ffffff',
      },
      background:{
        default: '#0046A3'
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff' // the background color of the text field,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff' // the background color of the text field,
          },
        },
      },
      MuiBreadcrumbs:
      {
        styleOverrides:
        {
          separator: {
            color: 'white',
            fontSize: '25px',
            position: 'relative', 
          },
          root: {
            color: 'white',
            fontSize: '20px',
            position: 'relative', 
            fontWeight: 'bold'
          },
        }
      }
    },
  });

export { theme };