import React from 'react';
import logosmall from '../.././asset/image/logosmall.png';
import { theme } from './NavBarStylesMUI';
import './NavBar.scss';
import { ThemeProvider } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../.././i18n';
import { useTranslation } from 'react-i18next';
import avatarIcon from '../.././asset/image/social-media-avatar-profile-icon-isolated-on-square-background-vector.png'
import { useNavigate } from "react-router-dom";
import axiosClient from "../.././services/axiosInstance";
import Countdown, { CountdownApi } from 'react-countdown';
import { zeroPad } from 'react-countdown';
import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CardHeader,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import AppsIcon from '@mui/icons-material/Apps';
import DevicesIcon from '@mui/icons-material/Devices';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DvrIcon from '@mui/icons-material/Dvr';
import Directions from '../enums/enum-direction';
import { useStore } from '../../hooks/use-store';
import { HamburgerInterface } from './NavBarMenuItem';
import NavBarMenuItem from './NavBarMenuItem';

function Navbar() {
  const loginUserStore = useStore().loginUserStore;
  const navigate = useNavigate();
  const client = axiosClient(loginUserStore.getLoginUser.bearerToken);
  const { t, i18n } = useTranslation();

  const autoLogOutTime = 3599000;
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [dateChange, setDateChange] = React.useState<number>(Date.now() + autoLogOutTime);

  const [hamburgerMenuActive, setHamburgerMenuActive] = React.useState<boolean>(false);
  const [anchorHamburgerMenu, setAnchorHamburgerMenu] = React.useState<null | HTMLElement>(null);
  const openHamburgerMenu = Boolean(anchorHamburgerMenu);

  const handleHamburgerMenuClose = () => {
    setHamburgerMenuActive(false);
    setAnchorHamburgerMenu(null);
  };
  const itemsHamburgerMenu: HamburgerInterface[] = [ 
    { label: "About", icon: <AppsIcon/>, href: Directions.About}, 
    { label: t("devices"), icon: <DevicesIcon/>, href: Directions.Devices},
    { label: t("machines"), icon: <DisplaySettingsIcon/>, href: Directions.Machines},
    { label: t("users"), icon: <PersonIcon/>, href: Directions.Users},
    { label: t("reason_template"), icon: <DescriptionIcon/>, href: Directions.ReasonTemplate},
    { label: t("user_machines"), icon: <ContactPageIcon/>, href: Directions.UserMachine}, 
    { label: t("user_status"), icon: <DvrIcon/>, href: Directions.UserMachineSensor},
    { label: t("logout"), icon: <LogoutIcon/>, href: null}
  ];

  const events = [
    "mousemove"
  ];
  React.useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        setDateChange(Date.now() + autoLogOutTime);
      });
    });
  });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenElNav = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseElNav = () => {
    setAnchorElNav(null);
  };

  const renderer = ({ minutes, seconds, completed }:any) => {
    if (completed) {
      LogOut();
    } else {
      return <Typography>Timer:&nbsp;&nbsp;&nbsp;&nbsp;{zeroPad(minutes)}:{zeroPad(seconds)}</Typography>;
    }
  };

  const onClickLanguageChange = (e:any) =>
  {
    handleCloseElNav();
    const language = e.target.id;
    i18n.changeLanguage(language);
    changeText(language=="en"?"English":"Deusth");    
  };

  const LogOut = () =>
  {
    client .get("/logout")    
      .then((response) => { 
        console.log("Response:", response.data); 
      }) 
      .catch((error) => { 
        console.error("Error:", error); 
      })
      .finally(() => { 
        loginUserStore.logout();
        navigate('/', { replace: true });
      });   
  };

  const onClickHamburgerItem = (e: any, href:any) =>
  {
    if(href==null)
    {
      LogOut();
    }
    else
    {
      navigate(href, { replace: true });
    }
  };

  const [buttonText, setButtonText] = React.useState(i18n.language=="en"?"English":"Deusth"); 
  const changeText = (textCh:any) => setButtonText(textCh);
  const email = loginUserStore.getLoginUser ? loginUserStore.getLoginUser.email : "";
  
  return (
    <ThemeProvider theme={theme}>
    <AppBar position="static">
      <Box>
        <Toolbar disableGutters>
          <Box>
            <Box className="box-icon" onClick={e => {setHamburgerMenuActive(!hamburgerMenuActive); setAnchorHamburgerMenu(e.currentTarget);}}>
              <IconButton disableRipple={true} disableFocusRipple={true} className="custom-hamburger-icon">
                { hamburgerMenuActive ? <ClearIcon fontSize='large'/> : <MenuIcon fontSize='large'/> }
              </IconButton>
            </Box>
            <Menu id="basic-menu"
              anchorEl={anchorHamburgerMenu}
              open={openHamburgerMenu}
              onClose={handleHamburgerMenuClose}
              MenuListProps={{'aria-labelledby': 'basic-button',
                style: {
                  width: '35ch',
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              {
                itemsHamburgerMenu.map((item:HamburgerInterface)=>{
                  return  <NavBarMenuItem key={item.label} item = {item} onClickButton = {onClickHamburgerItem} />;
                })
              }
            </Menu>
          </Box>
          <Box className='main-logo'>
            <img src={logosmall} className="App-logoNew" alt="logoNew"/>
          </Box> 
          
          <Box className='box-breadcrumbs'/>

          <Box className="box-countdown">
            <Countdown date={dateChange} renderer={renderer} key={dateChange}/>
          </Box>
          <Box>
            <Button variant="outlined" onClick={handleOpenElNav} className='language-button'>
              {buttonText}
            </Button>
            <Menu
                className='margin-menu'
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseElNav}
              >
                <MenuItem onClick={onClickLanguageChange}>
                  <Typography textAlign="center" id="en">English</Typography>
                </MenuItem>
                <MenuItem onClick={onClickLanguageChange} >
                  <Typography textAlign="center" id = "de">Deusth</Typography>
                </MenuItem>
              </Menu>
          </Box>
          <Box className='box-tooltip'>          
            <Tooltip title="" onClick={handleOpenUserMenu} >
              <Box className='div-menu' >
              <CardHeader
                avatar={
                  <Avatar variant='square'
                    src={avatarIcon}
                  />
                }
                title={email}
              />  
              <KeyboardArrowDownIcon className='arrow-icon'/>
              </Box>                
            </Tooltip>
            <Menu
              className='margin-menu'
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={LogOut}>
                  <Typography textAlign="center">{t("logout")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
    </ThemeProvider>
  );
}

export default Navbar;