import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet }
    from "react-router-dom";
import Devices from "./pages/Devices";
import Login from "./pages/Login";
import Machines from "./pages/Machines";
import Typography from '@mui/material/Typography';
import About from "./pages/About";
import Users from "./pages/Users";
import ReasonTemplate from './pages/ReasonTemplate';
import UserMachines from './pages/UserMachines';
import UserMachinesSensors from "./pages/UserMachinesSensors";
import Directions from './asset/enums/enum-direction';
import {StoreContext} from './contexts/store-context';
import { store } from "./stores/root-store";

function App() {
    const ProtectedRoute = ({
        redirectPath = '/',
        children,
      }:any) => {
        const user = store.loginUserStore.getLoginUser;
        if (!user.bearerToken) {
          return <Navigate to={redirectPath} replace />;
        }
      
        return children ? children : <Outlet />;
      };        

    return (
        <StoreContext.Provider value={store}>
            <Router>
                <Routes>
                    <Route path="/"
                        element={<Login />} />   
                    <Route element={<ProtectedRoute/>}>
                        <Route path={Directions.Devices} element={<Devices />} />
                        <Route path={Directions.Machines} element={<Machines />} />
                        <Route path={Directions.About} element={<About />} />
                        <Route path={Directions.Users} element={<Users />} />
                        <Route path={Directions.ReasonTemplate} element={<ReasonTemplate />} />
                        <Route path={Directions.UserMachine} element={<UserMachines />} />
                        <Route path={Directions.UserMachineSensor} element={<UserMachinesSensors />} />
                    </Route>
                    <Route path="*" element={<Typography>There's nothing here: 404!</Typography>} />       
                </Routes>
            </Router>
        </StoreContext.Provider>
    );
}
 
export default App;
