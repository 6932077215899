import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Switch,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
  } from '@mui/material';
import '../i18n';
import "./UserMachines.scss";
import { useTranslation } from 'react-i18next'; 
import { observer } from "mobx-react-lite";
import StarterBox from '../asset/components/StarterBox';
import { useStore } from '../hooks/use-store';
import { IMachineRulesUser, IMachineRulesMachine } from '../stores/machine-rules-store';

function UserMachines(){
    const { t } = useTranslation();
    const { v4: uuidv4 } = require('uuid');
    const machineRules = useStore().machineRulesStore;
    const { listUsers, getListUser, getMachinesByUserId, setGrantAccessMachine, setDisableAccessMachine } = machineRules;
    const [ selectedUser, setSelectedUser ] = useState<number>(-1);
    const [ selectedMachines, setSelectedMachines ] = useState<IMachineRulesMachine[]>([]);

    useEffect(() => {
        if(listUsers.length==0){
            getListUser();
        }
    }, []);

    const clickRowUsersTable = async(id: number) => {
        setSelectedUser(id);
        setSelectedMachines(await getMachinesByUserId(id));
    }

    const clickRowMachineTable = async(machine: IMachineRulesMachine) => {
        if(machine.isSelected)
            await setDisableAccessMachine( selectedUser, machine.id );
        else
            await setGrantAccessMachine(selectedUser, machine.id);
        setSelectedMachines(await getMachinesByUserId(selectedUser));
    }

    return(
        <StarterBox>
            <Typography variant="h5">{t("user_machines_info")}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <TableContainer component={Paper} className='table-height'>
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left"><b>Users</b></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { listUsers.length > 0 ? 
                            listUsers.map((row: IMachineRulesUser, idx) => {
                            return (
                            <TableRow key={idx} onClick={() => clickRowUsersTable(row.id)} className = {selectedUser === row.id ? "tableGrey" : "tableWhite"}>
                                <TableCell>{selectedUser===row.id ? '+' : '-'}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>)
                            }) : <TableRow key={uuidv4()}/>
                        }
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={6}>
                <TableContainer component={Paper} className='table-height'>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left"><b>Machines</b></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { selectedMachines.length > 0 && selectedUser != -1? 
                            selectedMachines.map((row: IMachineRulesMachine, idx) => {
                            return (
                            <TableRow key={idx}>
                                <TableCell><Switch checked = {row.isSelected} onChange={() => clickRowMachineTable(row)}/></TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>)
                            }) : <TableRow key={uuidv4()}/>
                        }
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </StarterBox>
    )
}

export default observer(UserMachines);