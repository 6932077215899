import {
    List,
  } from '@mui/material'; 
import { observer } from "mobx-react-lite";
import { useStore } from '../../hooks/use-store';
import { MachinesItem } from '../../stores/machine-store-interfaces';
import MachineRow from './MachineRow';

const MachineList = observer(() => {
    const machineStore = useStore().machineStore;
    const { machines, selectedMachines, setSelectedItem } = machineStore;

    const onClickButton = (e:any, id: any) => {
        setSelectedItem(id);
    };

    const { v4: uuidv4 } = require('uuid');

    return(
        <List>
        {
            machines.map((item:MachinesItem)=>{
                return <MachineRow key={uuidv4()} item = {item} onClickButton={onClickButton} selectedIndex={selectedMachines}/>
            })
        }           
        </List>
    );
});

export default MachineList;