import React, {useEffect, useState} from 'react';
import {
    Box,
    FormControl,  
    NativeSelect,
    Grid,
    Typography
  } from '@mui/material'; 
import { useStore } from '../../hooks/use-store';
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import "../../pages/Machines.scss";

const CustomXAxisTick = (props:any) => {
    const { x, y, payload } = props;
    const date_ = new Date(payload.value);
    const formattedDate = date_.toTimeString().slice(0, 8);
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={40} y={0} dy={14} textAnchor="end" fill="#666" >
          {formattedDate}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const date_ = new Date(label);
      const formattedDate = date_.toTimeString().slice(0, 8) + '.' + date_.getMilliseconds();
      return (
        <div className="custom-tooltip">
          <p className="label">{formattedDate}</p>
          {payload.map((entry: any, index: number) => (
            <p key={`value-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  };


const MachineChart = observer(() => {
    const machineStore = useStore().machineStore;
    const { sensorDataSelectedMachine, updateSensorsData } = machineStore;
    const [secondsUpdate, setSecondsUpdate] = React.useState<any>(5);
    const { t } = useTranslation();

    useEffect(() => {
      const intervalId = setInterval(CallUpdateChart, secondsUpdate * 1000);

      return () => clearInterval(intervalId);
    }, [secondsUpdate]);

    const handleChange = (event: any) => {
      setSecondsUpdate(event.target.value);
    };

    function CallUpdateChart(){
      updateSensorsData();
    }

    return(
    <Box>
        <Grid container >
          <Box className="chart-box">
            <Typography className='padding-typgraphy'>{t("text_update")}</Typography>
          </Box>
          <Box className="chart-box">
            <FormControl sx={{ width: 70 }}>     
              <NativeSelect
                value={secondsUpdate}
                inputProps={{
                name: 'seconds',
                id: 'uncontrolled-native',
                }} onChange={handleChange}
              >
                <option value={5}>5s</option>
                <option value={15}>15s</option>
                <option value={30}>30s</option>
                <option value={60}>60s</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <ResponsiveContainer width="100%" height={340}>
            <LineChart
              data={sensorDataSelectedMachine}
              margin={{
                top: 5,
                right: 10,
                left: 10,
                bottom: 5,
              }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={<CustomXAxisTick />} height={40}/>
                <YAxis yAxisId="left">
                    <Label
                        value="Status Change"
                        position="insideLeft"
                        angle={-90}
                        style={{ textAnchor: "middle" }}
                   />
                </YAxis>
                <YAxis yAxisId="right" orientation="right">
                    <Label
                        value="Real Sensor Value"
                        position="insideRight"
                        angle={-90}
                        style={{ textAnchor: "middle" }}
                    />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Legend />         
                <Line yAxisId="left" isAnimationActive={false} type="monotone" dataKey="status" stroke="#82ca9d" dot={false}/>
                <Line yAxisId="right" isAnimationActive={false} type="monotone" dataKey="value" stroke="#8884d8"  dot={false}/>
            </LineChart>
        </ResponsiveContainer>
    </Box>);
});

export default MachineChart;