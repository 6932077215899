import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button
  } from '@mui/material';
import '../i18n';
import { useTranslation } from 'react-i18next'; 
import { observer } from "mobx-react-lite";
import StarterBox from '../asset/components/StarterBox';
import { useStore } from '../hooks/use-store';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { IUser } from '../stores/user-store';
import UsersTableRow from '../asset/components/UsersTableRow';
import UserDialog from '../asset/components/UserDialog';

function Users(){
    const userStore = useStore().userStore;
    const { getUsers, listUsers } = userStore;
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogUser, setDialogUser] = useState<IUser>({id: -1, name: "", email: "", roles: [], is_active: false, lang: ""});

    useEffect(() =>{
        if(listUsers.length==0){
            getUsers();
        }
    }, []);
    
    const refreshTable = (e:any) =>{
        getUsers();
    };

    const openDialogAddUser = () => {
        setDialogUser({id: -1, name: "", email: "", roles: [], is_active: false, lang: ""});
        setDialogOpen(true);
    }
    
    const closeDialogUser = () => {
        setDialogOpen(false);
    }

    return (
        <StarterBox>
            <Grid container alignItems="center">
                <Grid item xs={1}>
                    <Typography variant="h5">{t("users")}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <AutorenewIcon onClick={refreshTable}/>
                </Grid>
                <Grid item xs={10}>
                    <Button sx ={{float: "right"}} size="medium" variant="contained" onClick={openDialogAddUser}>Add</Button>
                </Grid>
            </Grid>
            <UserDialog onClickButtonClose={closeDialogUser} isOpen={dialogOpen} user={dialogUser}/>
            <TableContainer component={Paper} className='table-container'>
                <Table aria-label="device table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell align='center'><b>Email</b></TableCell>
                            <TableCell align='center'><b>{t("active")}</b></TableCell>
                            <TableCell align='center'><b>{t("roles")}</b></TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        listUsers.map((item: IUser, idx)=>{
                            return <UsersTableRow key={item.id} item ={item} color={idx % 2 === 0 ? "tableGrey" : "tableWhite"} setDialogUser={setDialogUser} setDialogOpen={setDialogOpen}/>
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </StarterBox>
    );
}

export default observer(Users);