import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";
import configFile from '.././Config';

const axiosClient = (token: string | null = null): AxiosInstance => {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
      };

  const client = axios.create({
    baseURL: configFile.siteURL,
    headers,
    timeout: 60000,
    withCredentials: false,
  });

  client.interceptors.request.use((config: any) => {
    //const token = localStorage.getItem("ACCESS_TOKEN");
    config.headers = config.headers || {};
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),);

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      //success status
      if (response.status === 200) {
        return response;
      } 
      //error status
      else {  
        const messages = response.data.messages
        if (messages) {
          if (messages instanceof Array) {
            return Promise.reject({ messages });
          }
          return Promise.reject({ messages: [messages] });
        }
        return Promise.reject({ messages: ["got errors"] });
      }
    },
    (error) => {
      //unauthorised error
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("ACCESS_TOKEN");
      } 
     //internal server error
     else if (error.response && error.response.status === 500) {
        return Promise.reject(error.response);
      } 
     //any other error
    else return Promise.reject(error);
    }
  );

  return client;
};

export default axiosClient;