import * as React from 'react';
import "../../pages/Machines.scss";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    Typography,
    CircularProgress
} from '@mui/material';
import { LabelGrid } from '../../pages/MachinesStyleMUI';
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";
import { useStore } from '../../hooks/use-store';
import { MachinesItem } from '../../stores/machine-store-interfaces';
import MachineRow from './MachineRow';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    minWidth:'250px',
    maxHeight: '80vh',
  },
  '& .MuiDialogTitle-root':{
    m: 0
  }
}));

const ListBox = styled(Box)(({ theme }) => ({
    overflow: 'auto',
    flexGrow: 1, 
    height: '60vh'
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 8,
    top: 8
}));

function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const machineStore = useStore().machineStore;
  const { machines, selectedMachines, setSelectedItem, showLoagingBar } = machineStore;
  const { v4: uuidv4 } = require('uuid');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

    const onClickButtonItem = (e:any, id: any) => {
        setSelectedItem(id); 
        setOpen(false);      
    };

  return (
    <React.Fragment>
        <LabelGrid onClick={handleClickOpen} item>
            <MenuIcon className="inline-blocks"/><Typography className="inline-blocks" variant='h5'>{t("machines")}</Typography>
        </LabelGrid>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          Select machine
        </DialogTitle>
        <CustomIconButton
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </CustomIconButton>
        <DialogContent dividers>
          <ListBox>
          { 
            !showLoagingBar ?      
              <List>
              {
                  machines.map((item:MachinesItem)=>{
                      return <MachineRow key={uuidv4()} item = {item} onClickButton={onClickButtonItem} selectedIndex={selectedMachines}/>
                  })
              } 
              </List>
            :
            <Box className="machine-loading-box">
              <Box className="machine-loading-container">
                <CircularProgress />
                <Typography sx={{pt:1,pl:"8px"}}>Loading</Typography>
              </Box>        
            </Box>
          }
          </ListBox>
        </DialogContent>
      </CustomDialog>
    </React.Fragment>
  );
}

export default observer(CustomizedDialogs);