import { observable, action, makeAutoObservable, computed } from 'mobx';
import { LoginUserStore } from './login-user-store';
import axiosClient from "../services/axiosInstance";

export interface DeviceItem{
    uuid: string,
    name: string,
    description: string,
    comment: string,
    macAddress: string,
    machineId: string|null,
    batteryPercent: number,
    status: number,
    lastSeen: string,
    appVersion: string
}

export enum DeviceButtonAction{
    Flash = "flash",
    ReloadSettings = "reload-settings",
    LogSet = "log-set",
    LogSetOff = "log-set-off"
}

export class DeviceStore{
    private loginUserStore:LoginUserStore;
    devices: DeviceItem[];

    constructor(loginUserStore:LoginUserStore){
        this.loginUserStore = loginUserStore;
        this.devices = [];
        makeAutoObservable(this, {devices:observable});
    }

    setDevices = action(async() =>{
        let deviceList:DeviceItem[]=[];

        try{
            const response = await this.loginUserStore.client.get("/device");
            response.data.data.forEach((value: any) => {
                let device:DeviceItem = {
                    uuid: value.uuid,
                    name: value.name,
                    description: value.description,
                    comment: value.comment,
                    macAddress: value.mac_address,
                    machineId: value.machine_id,
                    batteryPercent: value.battery_percent,
                    status: value.status,
                    lastSeen: value.last_seen,
                    appVersion: value.app_version
                };
                deviceList.push(device);
            });
        } catch(error){
            console.error("Error fetching devices:", error);
        } 

        this.devices = deviceList;
    });

    getDeviceById = action((uuid:string):DeviceItem|null=>{
        let device: DeviceItem|null = null;
        for(let i=0; i < this.devices.length; i++){
            let item =this.devices[i];
            if (uuid === item.uuid){
                device = item;
                break;
            }
        }
        return device;
    });

    setDeviceActionFlash = action (async(uuid: string) => {
        try{
            const response = await this.loginUserStore.client.get("/device/" + uuid + "/" + DeviceButtonAction.Flash);
            console.log(response);
            return true;
        } catch (error) {
            console.error("Error fetching device's" + DeviceButtonAction.Flash +" :", error);
            return false;
        }
    });

    setDeviceActionReload = action (async(uuid: string,) => {
        try{
            const response = await this.loginUserStore.client.get("/device/" + uuid + "/" + DeviceButtonAction.ReloadSettings);
            console.log(response);
            return true;
        } catch (error) {
            console.error("Error fetching device's" + action +" :", error);
            return false;
        }
    });

    setDeviceActionLogSet = action (async(uuid: string) => {
        try{
            const response = await this.loginUserStore.client.get("/device/" + uuid + "/" + DeviceButtonAction.LogSet);
            console.log(response);
            return true;
        } catch (error) {
            console.error("Error fetching device's" + DeviceButtonAction.LogSet +" :", error);
            return false;
        }
    });

    setDeviceActionLogSetOff = action (async(uuid: string) => {
        try{
            const response = await this.loginUserStore.client.get("/device/" + uuid + "/" + DeviceButtonAction.LogSetOff);
            console.log(response);
            return true;
        } catch (error) {
            console.error("Error fetching device's" + DeviceButtonAction.LogSetOff +" :", error);
            return false;
        }
    });
}