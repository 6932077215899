import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Typography
  } from '@mui/material'; 
import { useStore } from '../../hooks/use-store';
import { useTranslation } from 'react-i18next';
import { ITemplate } from "../../stores/reason-template-store";

interface ReasonTemplateValueDialogProps {
    onClickButtonClose: (e: any) => void;
    onClickButtonAddClose: (e: any) => void;
    englishTitle: string;
    templateId: number;
    isOpen:boolean;
  }

function ReasonTemplateValueCreateDialog({ onClickButtonClose, onClickButtonAddClose, englishTitle, templateId, isOpen }:ReasonTemplateValueDialogProps) {
    const reasonTemplateStore = useStore().reasonTemplateStore;
    const { createNewValues, addTemplateValueToTemplate } = reasonTemplateStore;
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [titleText, setTitleText] = useState<string>("");
    const [titleAnotherText, setTitleAnotherText] = useState<string>("");

    useEffect(() =>{
        setTitleText("");
        setTitleAnotherText("");
        setErrorMessage("");
        if (isOpen)
          setTitleText(englishTitle);
    }, [isOpen]);  

    const onClickButtonCreate = async(event:any) => {
        try {
            console.log(JSON.stringify({title: titleText, title_alt: titleAnotherText}));
            let res = await createNewValues(titleText, titleAnotherText);
            await addTemplateValueToTemplate(templateId, Number(res));
            onClickButtonClose(event);
            onClickButtonAddClose(event);
        } catch(error) {
            setErrorMessage((error as Error).message);
        }
    };

    return(
        <Dialog open={ isOpen }
            onClose={onClickButtonClose}
            PaperProps={{
            component: 'form',
        }}>
            <DialogTitle>Create value</DialogTitle>
            <DialogContent>
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="English"
                  variant='filled'
                  name='title'
                  value={titleText}
                  onChange={(e) => {
                    setTitleText(e.target.value);
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="Non-English"
                  variant='filled'
                  name='title'
                  value={titleAnotherText}
                  onChange={(e) => {
                    setTitleAnotherText(e.target.value);
                  }}
                />
            </DialogContent>
            <DialogActions>
              <Typography variant="body2" color="#FF0000">{errorMessage}</Typography>
              <Button onClick={onClickButtonClose}>Cancel</Button>
              <Button onClick={onClickButtonCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReasonTemplateValueCreateDialog;