import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    ListItemIcon,
  } from '@mui/material'; 
import '../../pages/Machines.scss';
import { useStore } from '../../hooks/use-store';
import { observer } from "mobx-react-lite";
import { MachinesItem, SensorsItem } from '../../stores/machine-store-interfaces';
import { LabelGridMachine } from '../../pages/MachinesStyleMUI';
import getSensorIcon from './MachineIconItem';
import SensorBox from './SensorBox';

function iconItem(item: SensorsItem|null){
  var icon = <Box className="magnet-box"/>;

  if(item != null && item.type != null)
  {
    icon = getSensorIcon(item.type, false);
  }

  return(
      <ListItemIcon>{icon}</ListItemIcon>
  );
} 

function MachineBox () {
  const machineStore = useStore().machineStore;
  const { getMachineById, selectedMachines, showLoagingBar, getSensorById } = machineStore;
  const [machineInfo, setMachineInfo] = useState<MachinesItem|null>(null);
  const [sensorInfo, setSensorInfo] = useState<SensorsItem|null>(null);

  useEffect(()=>{
    downloadMachineData();
  }, [selectedMachines]);

  const downloadMachineData = async() => {
    let item =getMachineById(selectedMachines);
    setMachineInfo(item);
    if(item != null){
      setSensorInfo(null);
    } else {
      let sensor = getSensorById(selectedMachines);
      setSensorInfo(sensor);
    }
  }

  const getMachineName = () =>{
    var text = "";
    if(machineInfo){
      text  = machineInfo.name;
    } else if (sensorInfo){
      text = sensorInfo.machineName + ", " + sensorInfo.sensorName;
    }
    return text;
  };

  return(
  <Box>
  { selectedMachines!='' && !showLoagingBar?  
    <Box>
      <LabelGridMachine item>{iconItem(getSensorById(selectedMachines))}<Typography variant='h5'>{ getMachineName()}</Typography></LabelGridMachine>
      { sensorInfo != null ? 
        <SensorBox sensorInfo={sensorInfo}/>: 
        <Typography variant="subtitle1">Please finish setup the machine sensor using you mobile device.</Typography>
      }
    </Box> : ""
  }
  </Box>
  );
}

export default observer(MachineBox);
