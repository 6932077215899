import Typography from '@mui/material/Typography';
import StarterBox from '../asset/components/StarterBox';
import { useStore } from '../hooks/use-store';

export default function(){
    const userStore = useStore().userStore;

    return (
        <StarterBox>
            <Typography variant='h3'>About</Typography>
            <Typography variant='body1'>The monitoring, especially of older machines, is often complex and very expensive. Small and medium-sized companies in particular often shy away from the investments associated with Industry 4.0 solutions. An IT project is always necessary for implementation. SILVA light is a solution for machine monitoring that does not require any investment – simply connect and ready to go – in just a few minutes.</Typography>
        </StarterBox>
    );
}