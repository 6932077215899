enum Directions {
    About = "/about",
    Devices = "/devices",
    Machines = "/machines",
    Users = "/users",
    ReasonTemplate = "/reason-template",
    UserMachine = "/user-machines",
    UserMachineSensor = "/user-machines-sensor"
}

export default Directions;