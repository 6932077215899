import React from 'react';
import {
    Box,
    Container
} from '@mui/material';
import NavBar from './NavBar';
import '../../App.scss';
import { useLocation } from "react-router-dom";
import Directions from '../enums/enum-direction';

function StarterBox({children}:any) {
    const location = useLocation();
    
    return (
        <Box className="starter-box">
            <NavBar />
            { location.pathname===Directions.Machines ? <Box component="main" className='starter-container-devices'>{children}</Box> : <Box component="main" className='starter-container'>{children}</Box> }
        </Box>
    );
};

export default StarterBox;