import React from "react";
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";
import { useStore } from '../../hooks/use-store';
import {
    Box,
    Paper,
    Table, 
    TableBody,
    FormControl,
    Typography,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Button,
    NativeSelect
} from '@mui/material';
import {CalibrationSensorData, CalibrationData} from '../../stores/machine-store-interfaces';
import '../../pages/Machines.scss';

function StatusLogTable(){
    const { t } = useTranslation();
    const machineStore = useStore().machineStore;
    const { calibrationSensorDataSelectedMachine, calibrationDataSelectedMachine, selectedIdCalibrationData, setIdCalibration, minSecondsCalibration, minMinutesCalibration, setMinutesCalibration, setSecondsCalibration } = machineStore;
    const { v4: uuidv4 } = require('uuid');
    const seconds = Array.from({ length: 60 }, (_, index) => (index));
    const minutes = [0,1,2,3,4,5,6,7,8,9,10,15,20,25,30,35,40,45,50,55,60];

    const handleChange = (event: any) => {
        setIdCalibration(event.target.value as string);
      };

    const handleChangeMinutes = (event: any) => {
        setMinutesCalibration(event.target.value as number);
      };

    const handleChangeSeconds = (event: any) => {
        setSecondsCalibration(event.target.value as number);
      };
    
    return (
        <Box>
            <Box>
                <Typography variant="h6" className="table-box">{t("status_log")}</Typography>
                <Box className="table-box">
                <FormControl fullWidth>               
                    <NativeSelect
                        value={selectedIdCalibrationData}
                        onChange={handleChange}
                    >
                        {
                            calibrationDataSelectedMachine.map((item:CalibrationData)=>{
                                return <option key={uuidv4()} value={item.id}>{item.paramTitle=="" ? "Status Value " + item.paramName : item.paramTitle}</option>;
                            })
                        }
                    </NativeSelect>
                </FormControl>
                </Box>
                <Typography variant="body2" className="table-box">{t("min_duration")} (mm:ss)</Typography>
                <Box className="table-box">
                    <FormControl className="form-control">
                        <NativeSelect
                            value={minMinutesCalibration}
                            onChange={handleChangeMinutes}
                        >
                        {
                            minutes.map((item:any)=>{
                                return <option key={uuidv4()} value={item}>{item}</option>;
                            })
                        }
                        </NativeSelect>
                    </FormControl>
                    <FormControl className="form-control">
                        <NativeSelect
                            value={minSecondsCalibration}
                            onChange={handleChangeSeconds}
                        >
                        {
                            seconds.map((item:any)=>{
                                return <option key={uuidv4()} value={item}>{item}</option>;
                            })
                        }
                        </NativeSelect>
                    </FormControl>
                </Box>
            </Box>
            <TableContainer component={Paper} className="table-size">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell >{t("start_event")}</TableCell>
                            <TableCell align="center">{t("duration")}</TableCell>
                            <TableCell align="center">{t("reason")}</TableCell>
                            <TableCell align="center">{t("notes")}</TableCell>
                            <TableCell align="center">{t("user")}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {calibrationSensorDataSelectedMachine.map((row: CalibrationSensorData) => (
                            <TableRow key={uuidv4()} id={row.id}>
                                <TableCell className="table-cell">{row.timestampStart}</TableCell>
                                <TableCell align="center" className="table-cell">{row.durationHuman}</TableCell>
                                <TableCell align="center" className="table-cell">{row.reasonStatus}</TableCell>
                                <TableCell align="center" className="table-cell">{row.description}</TableCell>
                                <TableCell align="center" className="table-cell">{row.userName}</TableCell>
                                <TableCell align="right" className="table-cell">{row.reasonId == null ? <Button>{t("add_reason")}</Button>:<Button>{t("edit")}</Button>}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default observer(StatusLogTable);