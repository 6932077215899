import React, { useEffect, useState } from 'react';
import {
    TableRow,
    TableCell,
  } from '@mui/material'; 
import '../../pages/Machines.scss';
import { useStore } from '../../hooks/use-store';
import { NotificationItem } from '../../stores/machine-store-interfaces';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MachineDialogNotification from './MachineDialogNotification';

interface SensorInfoRowProps {
    idx: number;
    row: NotificationItem;
}

const SensorInfoRow = ({ idx, row}: SensorInfoRowProps) =>  {
    const machineStore = useStore().machineStore;
    const { getCalibrationParamName } = machineStore;
    const [dialogEditNotificationIndex, setDialogEditNotificationIndex] = useState<number|null>(null);

    const openDialogEditNotification = (index: number) => {
        setDialogEditNotificationIndex(index);
      }
    
    const closeDialogEditNotification = () => {
        setDialogEditNotificationIndex(null);
    }
    return(
        <TableRow key={idx} id={row.id}>
            <TableCell>{getCalibrationParamName(row.calibrationId)}</TableCell>
            <TableCell align="center">{row.userEmail}</TableCell>
            <TableCell align="center">{row.isEmail == 1 ? "+":"-"}</TableCell>
            <TableCell align="center">{row.isPush == 1 ? "+":"-"}</TableCell>
            <TableCell align="center">{row.isSendOnce == true ? "+":"-"}</TableCell>
            <TableCell align="center">{row.emailPrefix}</TableCell>
            <TableCell align="right"><EditNoteIcon onClick={() => openDialogEditNotification(idx)}/></TableCell>
            <MachineDialogNotification row={row} open={dialogEditNotificationIndex} index = {idx} onClickButtonClose={closeDialogEditNotification} isOpen={false}/>
        </TableRow>
    );
}

export default SensorInfoRow;