import {
    Box,
} from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import MagneticIcon from '../image/magnet.svg';
import MagneticIconBlue from '../image/magnet-blue.svg';
import PressureIcon from '../image/pressure.svg';
import LinearAccelerationIcon from '../image/linear-acceleration.svg';
import PressureIconBlue from '../image/pressure-blue.svg';
import LinearAccelerationIconBlue from '../image/linear-acceleration-blue.svg';
import { TypeSensor } from '../enums/enum-type-sensor';


export default function getSensorIcon (type: number, isSelected: boolean){
    var icon = <Box className="magnet-box"/>;

    switch(type){
        case TypeSensor.ACCELEROMETER: {
            icon = <MemoryIcon className={isSelected ? 'icon-selected':'icon'}/>;
            break;
        }
        case TypeSensor.LIGHT: {
            icon = <EmojiObjectsIcon className={isSelected ? 'icon-selected':'icon'}/>
            break;
        }
        case TypeSensor.MAGNETIC_FIELD: {
            icon = <Box className="magnet-box"><img src={isSelected ? MagneticIconBlue : MagneticIcon} /></Box>
            break;
        }
        case TypeSensor.LINEAR_ACCELERATION: {
            icon = <Box className="magnet-box"><img src={isSelected ? LinearAccelerationIconBlue : LinearAccelerationIcon} /></Box>
            break;
        }
        case TypeSensor.PRESSURE: {
            icon = <Box className="magnet-box"><img src={isSelected ? PressureIconBlue :PressureIcon} /></Box>
            break;
        }
    }

    return icon;
}

