import React from 'react';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import {
  TableCell,
  TableRow,
  Button
} from '@mui/material';
import { useStore } from '../../hooks/use-store';
import { DeviceItem } from '../../stores/device-store';
import { observer } from "mobx-react-lite";
import { DeviceButtonAction } from "../../stores/device-store";

interface DeviceTableRowProps {
  items: DeviceItem;
}

const DeviceTableRow = ( {items}: DeviceTableRowProps) => {
  const deviceStore = useStore().deviceStore;
  const { setDeviceActionFlash } = deviceStore;
  const { t } = useTranslation();
  const [disabledFlash, setDisabledFlash] = React.useState<boolean>(false);
  
  const clickFlash = async(e:any) =>{
    setDisabledFlash(true);
    await setDeviceActionFlash(e.target.id);
    setDisabledFlash(false);
  };

  const itemButton = items.batteryPercent >= 90? <Button id={items.uuid} onClick={clickFlash} disabled={disabledFlash}>{t("flash")}</Button> : "";

  return (
    <TableRow key={items.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">{items.name}</TableCell>
      <TableCell align="center">{items.description}</TableCell>
      <TableCell align="center">{items.lastSeen}</TableCell>
      <TableCell align="center">{items.appVersion}</TableCell>
      <TableCell align="center">{items.batteryPercent}</TableCell>
      <TableCell align="center">{itemButton}</TableCell>
    </TableRow>
  );
};

export default observer(DeviceTableRow);