import React from 'react';
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Box,
} from '@mui/material';
import { MachinesItem, SensorsItem } from '../../stores/machine-store-interfaces';
import getSensorIcon from './MachineIconItem';

interface MachineRowProps {
    item: MachinesItem;
    onClickButton: (e: any, id: any) => void;
    selectedIndex: string;
  }

function IconItem(type:any, selectedIndex:string, id:string|null){
    var icon = <Box className="magnet-box"/>;
    if(type != null){
        icon = getSensorIcon(type, selectedIndex === id);
    }

    return(
        <ListItemIcon>{icon}</ListItemIcon>
    );
}

const MachineRow = ({ item, onClickButton, selectedIndex }:MachineRowProps) =>  {
    var needIcon = false;
    var type: number|null = null;
    if(item.sensors!=null){
        for(let i = 0; i < item.sensors.length; i++){
            let sensor = item.sensors[i];
            if(sensor.isActive){
                needIcon = true;
                type =  sensor.type;
                break;
            }
        }
    }
    
    const { v4: uuidv4 } = require('uuid');

    return(     
        <Box>
        {
            (item.sensors == null || item.sensors.length == 0 || type == null) ?
            <ListItemButton selected={selectedIndex === item.id} className='list-item-machines' onClick={(event) => onClickButton(event, item.id)}>
                {IconItem(type, selectedIndex, item.id)}
                <ListItemText className={needIcon==false?'nonactive-item':''}>{item.name}</ListItemText>
            </ListItemButton> :
            item.sensors.map((sensor: SensorsItem)=>{
                if(sensor.isActive==true)
                {
                    return (
                        <ListItemButton key={uuidv4()} selected={selectedIndex === sensor.id} className='list-item-machines' onClick={(event) => onClickButton(event, sensor.id)}>
                            {IconItem(sensor.type, selectedIndex, sensor.id)}
                            <ListItemText>{item.name}</ListItemText>
                        </ListItemButton>
                    );
                }
            })

        }
        </Box>
    );
}


export default MachineRow;