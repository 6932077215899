import React from 'react';
import '../../i18n';
import {
  TableCell,
  TableRow
} from '@mui/material';
import "../../pages/Users.scss";
import { IUser } from '../../stores/user-store';
import { observer } from "mobx-react-lite";
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface UserTableRowProps {
  item: IUser;
  setDialogUser:any;
  setDialogOpen: any;
  color: string;
}

const UsersTableRow = ( {item, setDialogUser, setDialogOpen, color}: UserTableRowProps) => {
    const openDialogEditUser = (user: IUser) => {
      setDialogUser(user);
      setDialogOpen(true);
    }

    return (
        <TableRow key={item.id} className = {color}>
            <TableCell component="th" scope="row">{item.name}</TableCell>
            <TableCell align="center">{item.email}</TableCell>
            <TableCell align="center">{item.is_active ? <CheckIcon/> : <CloseIcon/>}</TableCell>
            <TableCell align="center">{item.roles?.join(", ")}</TableCell>
            <TableCell align="right"><EditNoteIcon onClick={() => openDialogEditUser(item)}/></TableCell>
        </TableRow>
    );
};

export default observer(UsersTableRow);