import React, { useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Typography
  } from '@mui/material'; 
import { useStore } from '../../hooks/use-store';
import { useTranslation } from 'react-i18next';
import { IUser, IParametersUser } from "../../stores/user-store";
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';

interface UserDialogProps {
    onClickButtonClose: (e: any) => void;
    user:IUser;
    isOpen:boolean;
  }


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


function UserDialog({ onClickButtonClose, user, isOpen }:UserDialogProps) {
    const theme = useTheme();
    const userStore = useStore().userStore;
    const { postCreateNewUser, listRoles, postUpdateUser } = userStore;
    const { t } = useTranslation();
    const [personRoles, setPersonRoles] = React.useState<string[]>([]);
    const [checked, setChecked] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    useEffect(() =>{
      setPersonRoles(user.roles);
      setChecked(user.is_active);
      setErrorMessage("");
    }, [isOpen]);  

    const onClickButtonCreate = async(event: React.FormEvent<HTMLFormElement>) => {
      setErrorMessage("");
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let email = data.get('email');
      let username = data.get('username');
      let password = data.get('password');
      let roles = personRoles;
      let isActive = checked;
      let lang = navigator.language;

      const createdUser: IParametersUser = {
        name: username as string,
        email: email as string,     
        password: password as string,      
        is_active: isActive,
        lang: lang,
        roles: roles
      };

      try {
        await postCreateNewUser(createdUser);
        onClickButtonClose(event);
      } catch(error) {
        setErrorMessage((error as Error).message);
      }
    };

    const onClickButtonEdit = async(event: React.FormEvent<HTMLFormElement>) => {
      setErrorMessage("");
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let password = data.get('password');
      let roles = personRoles;
      let lang = navigator.language;
      let isActive = checked;
        
      const editedUser: IParametersUser = {};
      let changedValue: boolean =false;   
      if(password){
        editedUser.password = password as string;
        changedValue = true;
      }   
      if(user.is_active !== isActive){
        editedUser.is_active = isActive;
        changedValue = true;
      }
      if(user.lang !== lang){
        editedUser.lang = lang;
        changedValue = true;
      }
      if(JSON.stringify(roles) != JSON.stringify(user.roles)){
        editedUser.roles = roles;
        changedValue = true;
      }

      try {
        if(changedValue){
          await postUpdateUser(user?.id as number, editedUser);
        }
        onClickButtonClose(event);
      } catch(error) {
        setErrorMessage((error as Error).message);
      }
    };

    const handleChange = (event: SelectChangeEvent<typeof personRoles>) => {
        const {
          target: { value },
        } = event;
        setPersonRoles(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
      };

    return (
        <Dialog open={ isOpen }
            onClose={onClickButtonClose}
            PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                {user.email ? onClickButtonEdit(event) : onClickButtonCreate(event)}          
            },
        }}>
            <DialogTitle>{user.email? 'Edit' : 'Add'} user</DialogTitle>
            <DialogContent>
              {
                user.name ? <Typography variant="subtitle1">Name: {user.name}</Typography> :
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="Username"
                  variant='filled'
                  name='username'
                  defaultValue={user.name}
                />
              }
              {
                user.name ? <Typography variant="subtitle1">Email: {user.email}</Typography> :
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="Email"
                  name='email'
                  variant='filled'
                  defaultValue={user.email}
                />
              }
                <TextField
                  margin="normal"
                  fullWidth
                  required = {!user.email}
                  label="Password"
                  name='password'                
                  variant='filled'
                  defaultValue=""
                  autoComplete='off'
                />
                <div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-name-label">Role</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={ personRoles }
                            onChange={handleChange}
                            input={<OutlinedInput label="Role" />}
                            MenuProps={MenuProps}
                            required
                        >
                        {
                          Array.from(listRoles.entries()).map(([key, value]) => (
                            <MenuItem
                              key={key}
                              value={key}
                              style={getStyles(key, personRoles, theme)}
                            >
                              {value}
                            </MenuItem>
                        ))
                        }
                        </Select>
                    </FormControl>
                </div>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleChangeCheckBox}/>}
                    label="Active"
                    labelPlacement="start"               
                />
            </DialogContent>
            <DialogActions>
              <Typography variant="body2" color="#FF0000">{errorMessage}</Typography>
              <Button onClick={onClickButtonClose}>Cancel</Button>
              <Button type="submit">{user.email ? "Save" : "Create"}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserDialog;