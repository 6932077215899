import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Autocomplete,
    AutocompleteRenderInputParams 
  } from '@mui/material'; 
import { useStore } from '../../hooks/use-store';
import { useTranslation } from 'react-i18next';
import { ITemplate, IValue } from "../../stores/reason-template-store";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "../../pages/ReasonTemplate.scss";
import ReasonTemplateValueCreateDialog from "./ReasonTemplateValueCreateDialog";

interface ReasonTemplateValueDialogProps {
    onClickButtonClose: (e: any) => void;
    template:ITemplate;
    isOpen:boolean;
  }

function ReasonTemplateValueAddDialog({ onClickButtonClose, template, isOpen }:ReasonTemplateValueDialogProps) {
  const reasonTemplateStore = useStore().reasonTemplateStore;
  const { addTemplateValueToTemplate, deleteValueFromDB, values } = reasonTemplateStore;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [idValue, setIdValue] = React.useState<string>("");
  const [openTemplateValueCreateDialog, setOpenTemplateValueCreateDialog] = useState<boolean>(false);

  useEffect(() =>{
    setIdValue("");
    setErrorMessage("");
    setSelectedValue(null);
    setInputValue("");
  }, [isOpen]);  

  const handleChange = (event: SelectChangeEvent) => {
    setIdValue(event.target.value as string);
  };

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<IValue | null>(null);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
    setSelectedValue(null); 
  }

  const handleChangeAuto = (event: React.SyntheticEvent, value: IValue | string | null) => {
    setSelectedValue(typeof value === 'string' ? null : value);
  };

  const onClickButtonAdd = async(event: any) => {
    try {
        await addTemplateValueToTemplate(template.id, Number(selectedValue?.id));
        onClickButtonClose(event);
      } catch(error) {
        setErrorMessage((error as Error).message);
      }
  };

  const onClickButtonDelete = async() => {
    try {
        await deleteValueFromDB(Number(idValue));
        onClickButtonClose("");
      } catch(error) {
        setErrorMessage((error as Error).message);
      }
  };

  const openDialogTemplateValueCreate = () => {
    setOpenTemplateValueCreateDialog(true);
  }

  const closeDialogTemplateValueCreate = () => {
    setOpenTemplateValueCreateDialog(false);
  }

  return(
    <Dialog open={ isOpen }
        onClose={onClickButtonClose}
        PaperProps={{
        component: 'form',
    }}>
      <DialogTitle>Add template</DialogTitle>
        <DialogContent>
        {/*******<FormControl fullWidth className="select-padding">
          <InputLabel>Choose value</InputLabel>
          <Select
          value={idValue}
          label="Choose value"
          onChange={handleChange}
        >
          {
            values.map((row: IValue, idx)=>{
              return(<MenuItem key={idx} value={row.id}>{row.title} / {row.title_alt}</MenuItem>)
            })
          }
        </Select>
      </FormControl>*/}
          <Autocomplete
              className="select-padding"
              freeSolo
              getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
              options={values}
              value={selectedValue}
              onChange={handleChangeAuto}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  label="Choose or Enter"
                  placeholder="Start typing or select"
                  variant="outlined"
                />
              )}
            />
        </DialogContent>
        <DialogActions>
          <Typography variant="body2" color="#FF0000">{errorMessage}</Typography>
          {selectedValue != null ?<Button onClick={onClickButtonDelete}>Remove</Button> : ""}
          <Button onClick={onClickButtonClose}>Cancel</Button>
          {selectedValue != null ?<Button onClick={onClickButtonAdd}>Save</Button>:<Button onClick={openDialogTemplateValueCreate}>Create</Button>}
        </DialogActions>
        <ReasonTemplateValueCreateDialog onClickButtonClose={closeDialogTemplateValueCreate} isOpen={openTemplateValueCreateDialog} englishTitle={inputValue} templateId={template.id} onClickButtonAddClose={onClickButtonClose}/>
   </Dialog>
  );
};

export default ReasonTemplateValueAddDialog;